import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsFileEarmarkRuled } from 'react-icons/bs';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// ----------------------------------------------------------------------
import AppDeleteWalletDialog from '../app/AppDeleteWalletDialog';
import AppPermissionsWalletDialog from '../app/AppPermissionsWalletDialog';
import useSettingsStore from '../../../features/stores/settings';

export default function WalletsMoreMenu({ id, name }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { fetchAllWalletsAndUsersByWalletID } = useSettingsStore();

  const [isOpenPermission, setIsOpenPermission] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosePermission = () => {
    setIsOpenPermission(false);
  };

  const handleClickOpenPermission = async () => {
    await fetchAllWalletsAndUsersByWalletID(id);
    setIsOpenPermission(true);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <AppDeleteWalletDialog id={id} nick isOpenDialog={open} handleClose={handleClose} />
      <AppPermissionsWalletDialog
        id={id}
        name={name}
        isOpenDialogPermission={isOpenPermission}
        handleClosePermission={handleClosePermission}
      />

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
          <ListItemIcon>
            <RiDeleteBinLine width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleClickOpenPermission}>
          <ListItemIcon>
            <BsFileEarmarkRuled width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Permissões" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
