import { Icon } from '@iconify/react';
import bugFilled from '@iconify/icons-ant-design/bug-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Tooltip } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 234;

export default function AppBugReports(props) {
  return (
    <RootStyle>
      <IconWrapperStyle>{props.icon}</IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(props.value ? props.value : 0)}</Typography>
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={props.user ? props.users.map((u) => `${u.nick}\r`) : ''}
      >
        <Typography variant="subtitle2" sx={{ opacity: 0.72, cursor: 'pointer' }}>
          {props.title}
        </Typography>
      </Tooltip>
    </RootStyle>
  );
}
