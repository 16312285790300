import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import { useEffect } from 'react';
import useUserStore from '../../features/stores/user';

export default function LogoutUserDialog(props) {
  const { addNewUser, editUser, logoutUser } = useUserStore();

  const [id, setID] = React.useState('');

  useEffect(() => {
    setID(props.id);
  }, []);

  return (
    <Dialog
      open={props.isOpenLogoutUser}
      onClose={props.handleCloseEditUser}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deslogar usuário?</DialogTitle>

      <DialogActions>
        <Button color="error" onClick={props.handleCloseLogoutUser}>
          Cancelar
        </Button>
        <Button autoFocus onClick={() => logoutUser(id)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
