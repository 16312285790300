import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useEffect, useState, useContext, useCallback } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GiFishMonster } from 'react-icons/gi';
import { IoSettingsOutline } from 'react-icons/io5';
import { SiProbot } from 'react-icons/si';
import { formatDistance } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
// material
import {
  Card,
  Table,
  Stack,
  Box,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tabs,
  Tab
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { BotListToolbar, BotListHead, BotMoreMenu } from '../components/_dashboard/bot/components';

//
import AddAdsDialog from '../components/_dashboard/bot/components/AddAdsDialog';
import useBotStore from '../features/stores/bot';
import useUserStore from '../features/stores/user';
import { SocketContext } from '../services/socket/socket';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'url', label: 'Url', alignRight: false },
  { id: 'responsavel', label: 'Responsável', alignRight: false },
  { id: 'criador', label: 'Criador', alignRight: false },
  { id: 'horario', label: 'Horário', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_bot) => _bot.url.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Bot() {
  const socket = useContext(SocketContext);
  const [urlDelete, setUrlDelete] = useState();
  const [idDelete, setIdDelete] = useState();

  const [filter, setFilter] = useState([]);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { fetchAll, fetchByUser, bots, handleUpdateAds } = useBotStore();
  const { userData } = useUserStore();

  useEffect(() => {
    if (userData[0].role === 'ADMIN') {
      fetchAll();
    } else {
      fetchByUser(userData[0].nick);
    }
    setValue(0);
    handleChangeTab(null, 0);
  }, [fetchAll, fetchByUser, userData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filter.filter((n) => n.default !== 1).map((n) => n.id);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bots.length) : 0;

  const filteredBots = applySortFilter(filter, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredBots.length === 0;

  // const [isOpen, setIsOpen] = useState(false);

  const [openAddAds, setOpenAddAds] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpenAddAds = (e) => {
    setOpenAddAds(true);
  };

  const handleCloseAddAds = () => {
    setOpenAddAds(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      const bts = bots.filter((e) => e.default !== 1);
      setFilter(bts);
    }

    if (newValue === 3) {
      const defaults = bots.filter((filter) => filter.default === 1);
      setFilter(defaults);
    }

    if (newValue === 2) {
      const analise = bots.filter((filter) => filter.status === 0);
      const analises = analise.filter((e) => e.default !== 1);
      setFilter(analises);
    }

    if (newValue === 1) {
      const online = bots.filter((filter) => filter.status === 1);
      const on = online.filter((e) => e.default !== 1);
      setFilter(on);
    }
  };

  return (
    <Page title="Painel | BOT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            <SiProbot /> &nbsp; [BOT - Campanhas protegidas]
          </Typography>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleClickOpenAddAds}
            >
              Nova campanha
            </Button>

            <Button
              component={RouterLink}
              sx={{
                marginLeft: 4,
                backgroundColor: 'red',
                color: 'white'
              }}
              variant="contained"
              to="/dashboard/bot-atk"
            >
              <GiFishMonster size={20} /> &nbsp;Attack Ads
            </Button>

            {userData[0].role === 'ADMIN' ? (
              <Button
                disabled
                component={RouterLink}
                sx={{
                  marginLeft: 4,
                  backgroundColor: 'red',
                  color: 'white'
                }}
                variant="contained"
                to="/dashboard/stress"
              >
                <GiFishMonster size={20} /> &nbsp;Attack Stress
              </Button>
            ) : null}

            {userData[0].role === 'ADMIN' ? (
              <Button
                component={RouterLink}
                sx={{
                  marginLeft: 4,
                  backgroundColor: '#063970',
                  color: 'white'
                }}
                variant="contained"
                to="/dashboard/bot-settings"
              >
                &nbsp; <IoSettingsOutline size={20} /> &nbsp;
              </Button>
            ) : null}
          </Box>
        </Stack>

        <Card>
          <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Todas" {...a11yProps(0)} />
            <Tab label="Online" {...a11yProps(1)} />
            <Tab label="Análise" {...a11yProps(2)} />
            {userData[0].role === 'ADMIN' ? <Tab label="Default" {...a11yProps(3)} /> : null}
          </Tabs>
          <BotListToolbar
            numSelected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <BotListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={bots.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <AddAdsDialog isOpen={openAddAds} handleClose={handleCloseAddAds} />

                <TableBody>
                  {filteredBots
                    .sort((a, b) => (a.id > b.id ? -1 : 1))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row.url}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{row.responsavel}</TableCell>
                          <TableCell align="left">{row.criador}</TableCell>
                          <TableCell align="left">
                            {formatDistance(Date.parse(row.createdAt), new Date(), {
                              locale: ptLocale
                            })}{' '}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: 'bold',
                              color: `${row.status === 0 ? '#EAC600' : 'green'}`
                            }}
                          >
                            {row.status === 0 ? 'Em análise' : 'Campanha Online'}
                          </TableCell>
                          <TableCell align="right">
                            <BotMoreMenu id={row.id} url={row.url} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={bots.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
