import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';

import { FaWallet, FaEthereum } from 'react-icons/fa';
import { BiWorld } from 'react-icons/bi';
import { IoExtensionPuzzleSharp } from 'react-icons/io5';

import { AiFillBank } from 'react-icons/ai';
import { RiCoinsFill } from 'react-icons/ri';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Exchanges',
    path: '/dashboard/operador',
    icon: <FaEthereum width={22} height={22} size={22} />
  },
  {
    title: 'Dex',
    path: '/dashboard/auto-data',
    icon: <RiCoinsFill width={22} height={22} size={22} />
  },
  // {
  //   title: 'Swap',
  //   path: '/dashboard/swap',
  //   icon: <AiOutlineSwap width={22} height={22} />
  // },
  // {
  //   title: 'Bot',
  //   path: '/dashboard/bot',
  //   icon: <BiBot width={22} height={22} />
  // },
  {
    title: 'Banking',
    path: '/dashboard/banking',
    icon: <AiFillBank width={22} height={22} />
  },
  {
    title: 'Domínios',
    path: '/dashboard/dominios',
    icon: <BiWorld width={22} height={22} />
  },
  {
    title: 'Extensão',
    path: '/dashboard/extension',
    icon: <IoExtensionPuzzleSharp width={22} height={22} />
  }
  // {
  //   title: 'CC Rig',
  //   path: '/dashboard/products',
  //   icon: <AiFillCreditCard width={22} height={22} />
  // },
  // {
  //   title: 'Tools',
  //   path: '/dashboard/tools',
  //   icon: <GiLightningSpanner width={22} height={22} />
  // },
  // {
  //   title: 'Whats Notify',
  //   path: '/dashboard/notify',
  //   icon: <AiFillRobot width={22} height={22} />
  // },
  // {
  //   title: 'Spam Tools',
  //   path: '/dashboard/blog',
  //   icon: <SiMinutemailer width={22} height={22} />
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // },
];

export default sidebarConfig;
