import React, { useState, useEffect, useContext, useCallback } from 'react';
// material
import { Card, Box, Button, Typography, Grid } from '@mui/material';
//
import { formatDistance } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdBlock } from 'react-icons/md';
import { GiCheckMark } from 'react-icons/gi';
import { SiGoogleadsense } from 'react-icons/si';
import { BiTimeFive } from 'react-icons/bi';
import { FaWallet } from 'react-icons/fa';
import { BsFillDisplayFill } from 'react-icons/bs';
import AppClients from './AppNewUsers';
// ----------------------------------------------------------------------

import { SocketContext } from '../../../services/socket/socket';
import useStore from '../../../features/stores/automatic';
import useUsers from '../../../features/stores/user';

function AppAutoData() {
  const { fetchAutomatics, addAutomatic, automatics, deleteAutomatic } = useStore();
  const { userData } = useUsers();
  // let audio = new Audio("/beep-21.mp3");
  const destroyAudio = new Audio('/static/audio/destroy.mp3');
  const newClientAudio = new Audio('/static/audio/auto.mp3');
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const socket = useContext(SocketContext);

  useEffect(() => {
    fetchAutomatics();
  }, [fetchAutomatics]);

  const handleNewClient = useCallback(
    (data) => {
      newClientAudio.play();
      addAutomatic(data);
    },
    [addAutomatic, newClientAudio]
  );

  const handleDeleteAutomatic = (id) => {
    deleteAutomatic(id);
    destroyAudio.play();
  };

  useEffect(() => {
    socket.on('newAutomatic', (data) => {
      handleNewClient(data);
    });

    return () => {
      socket.off('newAutomatic');
    };
  }, [handleNewClient, socket]);

  return (
    <>
      <Grid container spacing={4} mt={1}>
        {automatics &&
          automatics
            .sort((a, b) => (a.id > b.id ? -1 : 1))
            .map((info, index) => (
              <Grid item sx={{ width: '100%', height: 100 }} key={index}>
                <Card sx={{ width: '100%', height: 80 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      backgroundColor: '#D1F2FF',
                      height: 20
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        color: '#0C53B7',
                        height: '100%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      <BiTimeFive size={15} />
                      &nbsp;{' '}
                      {formatDistance(Date.parse(info.createdAt), new Date(), {
                        locale: ptLocale
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        color: '#0C53B7',
                        height: '100%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      <FaWallet />
                      &nbsp; Method: {info.method}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        color: '#0C53B7',
                        height: '100%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      <BsFillDisplayFill />
                      &nbsp;Tela: {info.house}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        color: '#0C53B7',
                        height: '100%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      <SiGoogleadsense />
                      &nbsp;Ads: {info.ads}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        color: '#0C53B7',
                        height: '100%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      <FaWallet />
                      &nbsp; Wallet: {info.wallet_name}
                    </Typography>
                    <Box
                      sx={{
                        marginRight: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          height: 20,
                          fontSize: 11,
                          bgcolor: '#7A4F00',
                          color: '#FFF7CD',
                          '&:hover': { bgcolor: '#FFF7CD', color: '#7A4F00' }
                        }}
                      >
                        <MdBlock /> Block
                      </Button>

                      <Button
                        variant="contained"
                        disabled={!(userData && userData[0].role === 'ADMIN')}
                        sx={{
                          height: 20,
                          m: 0.5,
                          fontSize: 11,
                          color: 'white',
                          bgcolor: '#7F1900',
                          '&:hover': { bgcolor: '#FF3100', color: 'white' }
                        }}
                        onClick={(e) => handleDeleteAutomatic(info.id)}
                      >
                        <AiOutlineDelete />
                        Excluir
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          height: 20,
                          fontSize: 11,
                          bgcolor: '#02514A',
                          color: '#C8FACD',
                          '&:hover': { bgcolor: '#C8FACD', color: '#02514A' }
                        }}
                      >
                        <GiCheckMark />
                        Finalizar
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      height: '76%',
                      p: 0.8
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        border: 'solid 1px #D1F2FF',
                        width: '75%',
                        height: '100%',
                        borderRadius: 0.5,
                        p: 0.4,
                        alignItems: 'center',
                        textAlign: 'center'
                      }}
                    >
                      <Typography sx={{ fontSize: '1.1rem' }}>{info.seed}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        border: 'solid 1px #D1F2FF',
                        width: '24%',
                        height: '100%',
                        borderRadius: 0.5,
                        p: 0.4
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '1.3rem',
                          color: `${info.usd_value > 0 ? '#0B831F' : '#F11414'}`
                        }}
                      >
                        ${info.usd_value}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
      </Grid>
    </>
  );
}

export default React.memo(AppAutoData);
