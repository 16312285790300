import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import useOperableStore from '../features/stores/operable';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DeleteOperablesDialog(props) {
  const destroyAudio = new Audio('/static/audio/destroy.mp3');
  const { deleteOperables } = useOperableStore();

  const handleDeleteOperables = async () => {
    await deleteOperables();
    destroyAudio.play();
    props.handleClose();
  };

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deletar todas as infos? 🗑</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deseja excluir todas as infos?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={props.handleClose}>
          Não
        </Button>
        <Button onClick={() => handleDeleteOperables()} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(DeleteOperablesDialog);
