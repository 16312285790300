import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function RedirDialog({ openRedir, handleCloseRedir, url }) {
  return (
    <Dialog
      open={openRedir}
      onClose={handleCloseRedir}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Copiar Redir</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 800 }}>
              <Typography>
                <iframe
                  src={`./red.html?url=${url}`}
                  title="redir"
                  style={{ width: 800, height: 300, border: 'none' }}
                />
              </Typography>
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleCloseRedir}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
