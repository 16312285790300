import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import useServerStore from '../../../features/stores/server';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function AppDeleteServerDialog(props) {
  const destroyAudio = new Audio('/static/audio/destroy.mp3');
  const { deleteServer } = useServerStore();

  const handleDeleteServer = async (id) => {
    await deleteServer(id);
    destroyAudio.play();
    props.handleClose();
  };

  return (
    <Dialog
      open={props.isOpenDialog}
      TransitionComponent={Transition}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deletar servidor? 🗑</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deseja excluir o servidor <b>{props.name}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={props.handleClose}>
          Não
        </Button>
        <Button onClick={() => handleDeleteServer(props.id)} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(AppDeleteServerDialog);
