import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function LoginDialog(props) {
  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">⚠️ Atenção! ⚠️</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Devido a nova atualização da API, conceda as permissões solicitadas acima para poder
          continuar operando o sistema.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleClose}>
          Entendi
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(LoginDialog);
