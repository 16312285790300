import { filter } from 'lodash';
// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  TableBody,
  TableCell,
  Button,
  TableContainer,
  Link,
  Checkbox,
  TableRow,
  TablePagination,
  Table,
  Stack
} from '@mui/material';
// components
import { useState, useEffect } from 'react';
import { FaServer } from 'react-icons/fa';
import { IoMdTime } from 'react-icons/io';
import { HiStatusOffline, HiStatusOnline } from 'react-icons/hi';
import Page from '../components/Page';
import AddServerDialog from '../components/_dashboard/server/AddServerDialog';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { ServerListHead, ServerListToolbar, ServerMoreMenu } from '../components/_dashboard/server';
// components
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales
} from '../components/_dashboard/app';

import useUserStore from '../features/stores/user';
import useServerStore from '../features/stores/server';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'api', label: 'Api', alignRight: false },
  { id: 'ip', label: 'IP', alignRight: false },
  { id: '' }
];
// ----------------------------------------------------------------------

function geradorKey(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    if (result.length === 15) {
      result += 'j';
    }
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Servers() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { userData, users } = useUserStore();
  const { servers, fetchservers } = useServerStore();

  useEffect(() => {
    fetchservers();
  }, [fetchservers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = servers.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - servers.length) : 0;

  const filteredUsers = applySortFilter(servers, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const usersOn = users.filter((user) => user.status === 'Online');
  const usersAusente = users.filter((user) => user.status === 'Ausente');
  const usersOff = users.filter((user) => user.status === 'Offline');

  return (
    <Page title="Servidores | Team TradingView">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Servidores - Visão Geral</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers title="Total de servidores" value={servers.length} icon={<FaServer />} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              title="Usuários ON"
              icon={<HiStatusOnline />}
              value={usersOn.length}
              users={usersOn}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders
              title="Usuários Ausentes"
              icon={<IoMdTime />}
              value={usersAusente.length}
              users={usersAusente}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports
              title="Usuários OFF"
              icon={<HiStatusOffline />}
              value={usersOff.length}
              users={usersOff}
            />
          </Grid>
          {/*
          <Grid item xs={12} md={6} lg={7}>
            <AppWebsiteVisits />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={5}>
            <AppMostClickAds title="Países mais acessados" />
          </Grid> */}
          {/*
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>

        <Card
          sx={{
            marginTop: 5
          }}
        >
          <ServerListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ServerListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={servers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {userData && userData[0].role === 'ADMIN'
                    ? filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.name)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {row.name} {row.isMaster ? '- Principal' : null}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Link
                                  style={{
                                    pointerEvents: row.api === 'Sem Link' ? 'none' : ''
                                  }}
                                  href={`https://${row.api}`}
                                  underline="none"
                                  target="_blank"
                                >
                                  {row.api}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  {row.ip}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <ServerMoreMenu
                                  id={row.id}
                                  ip={row.ip}
                                  api={row.api}
                                  name={row.name}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : filteredUsers
                        .filter((page) => page.status !== false)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.name)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {row.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Link
                                  style={{
                                    pointerEvents: row.api === 'Sem Link' ? 'none' : ''
                                  }}
                                  href={`https://${row.api}/?token=${geradorKey(
                                    randomIntFromInterval(20, 50)
                                  )}`}
                                  underline="none"
                                  target="_blank"
                                >
                                  {row.api}
                                </Link>
                              </TableCell>
                              <TableCell align="left">{row.path}</TableCell>
                              <TableCell align="left">
                                <Typography
                                  sx={{
                                    color: `${row.status ? '#43BF2F' : '#DD4913'}`
                                  }}
                                >
                                  {row.status ? 'ON' : 'OFF'}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <ServerMoreMenu
                                  id={row.id}
                                  ip={row.ip}
                                  api={row.api}
                                  name={row.name}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={servers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
