import { useEffect } from 'react';
import { Stack, Button, Tooltip, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useSettingsStore from '../features/stores/settings';

import useAdsClickStore from '../features/stores/ads-clicks';

export default function ChipsBank() {
  const { adsClicks } = useAdsClickStore();

  return (
    <Stack direction="row" spacing={1} sx={{ pb: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          width: '28%'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#28A161',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: 20,
            width: 100,
            fontSize: 13
          }}
        >
          {adsClicks.length}&nbsp;
          {adsClicks.length > 1 ? 'Acessos' : 'Acesso'}
        </Typography>
        -
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#BC3B0E',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: 20,
            width: 130,
            fontSize: 13
          }}
        >
          {adsClicks.filter((e) => e.status === true).length}&nbsp;
          {adsClicks.filter((e) => e.status === true).length > 1 ? 'Bloqueados' : 'Bloqueado'}
        </Typography>
        -
        <Button
          variant="outlined"
          component={RouterLink}
          to="/dashboard/adsclicks"
          sx={{
            height: 23,
            width: 130,
            borderColor: '#0B831F',
            color: '#0B831F',
            '&:hover': { bgcolor: '#48B61F', color: 'white' }
          }}
        >
          Ads Clicks
        </Button>
      </Box>
    </Stack>
  );
}
