import { useContext, useCallback, useEffect } from 'react';
// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
import { AiOutlineFieldTime } from 'react-icons/ai';
import * as parser from 'ua-parser-js';
import ptLocale from 'date-fns/locale/pt-BR';
import { formatDistance, subHours } from 'date-fns';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { BsMouseFill } from 'react-icons/bs';
import { BiBlock } from 'react-icons/bi';
import { MdRouter, MdDns } from 'react-icons/md';
import { FaWolfPackBattalion } from 'react-icons/fa';
import Scrollbar from '../../Scrollbar';
import { CreateIpBlockColor } from '../../../utils/ui/createColors';
import { CreateOsIcon, CreateBrowserIcon } from '../../../utils/ui/createIcons';
import { SocketContext } from '../../../services/socket/socket';

import useIpsBlockedClick from '../../../features/stores/ip-blocked';

// ----------------------------------------------------------------------

IpsBlocked.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired
};

export default function IpsBlocked({ title, subheader, list, ...other }) {
  const { generateIpsBlockTxt, deleteAll, ipsBlockeds } = useIpsBlockedClick();

  return (
    <Card {...other}>
      <CardHeader title={`${title} ${ipsBlockeds.length}`} subheader={subheader} />
      <Scrollbar>
        <Stack spacing={1.5} sx={{ p: 1 }}>
          {list.map((news) => (
            <NewsItem key={news.id} news={news} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string
  })
};

function NewsItem({ news }) {
  const { id, ip, lang, referer, state, dns, clicks, userAgent, updatedAt, city, status, isBlock } =
    news;

  const { generateIpsBlockTxt, deleteBlocked, deleteAll, blockAttack } = useIpsBlockedClick();

  const downloadIPs = () => {
    generateIpsBlockTxt();
  };

  const deleteIpBlocked = (id) => {
    deleteBlocked(id);
  };

  return (
    <Box
      key={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: 0.5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            fontSize: 12,
            fontWeight: 'bold',
            color: `${clicks >= 6 ? 'white' : '#04297A'}`,
            height: '100%',
            width: '13%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: `${CreateIpBlockColor(clicks)}`,
            borderRadius: 0.5,
            p: 1
          }}
        >
          {clicks >= 6 ? <FaWolfPackBattalion /> : <BsMouseFill />}
          &nbsp;{clicks}&nbsp; {clicks >= 6 ? 'Attack' : 'Click'}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            fontSize: 12,
            fontWeight: 'bold',
            color: `${clicks >= 6 ? 'red' : '#04297A'}`,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1
          }}
        >
          <MdRouter size={15} />
          &nbsp;IP: {ip}
        </Typography>

        <Typography
          sx={{
            display: 'flex',
            fontSize: 12,
            fontWeight: 'bold',
            color: `${clicks >= 6 ? 'red' : '#04297A'}`,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1
          }}
        >
          <MdDns size={15} />
          &nbsp;DNS: {dns}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            fontSize: 11,
            fontWeight: 'bold',
            color: `${clicks >= 6 ? 'red' : '#04297A'}`,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1
          }}
        >
          <MdDns size={15} />
          &nbsp;Referer: {referer}
        </Typography>

        <Box
          sx={{
            width: '10%'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => blockAttack(id)}
            sx={{
              height: 25,
              width: 1,
              borderColor: `${isBlock ? '#43BF2F' : '#DD4913'}`,
              color: `${isBlock ? '#43BF2F' : '#DD4913'}`,
              '&:hover': {
                borderColor: `${isBlock ? '#43BF2F' : '#DD4913'}`,
                bgcolor: `${isBlock ? '#43BF2F' : '#DD4913'}`,
                color: 'white'
              }
            }}
          >
            {isBlock ? 'Unblock' : 'Block'}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          height: '40%',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderRadius: 0.5,
          textAlign: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '94%',
            backgroundColor: `${CreateIpBlockColor(clicks)}`,
            borderRadius: 0.5
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${clicks >= 6 ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            <AiOutlineFieldTime size={15} />
            {formatDistance(Date.parse(updatedAt), subHours(new Date(), 3), {
              locale: ptLocale
            })}{' '}
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${clicks >= 6 ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            {CreateOsIcon(parser(userAgent).os.name)}
            &nbsp; [{parser(userAgent).os.name}]
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${clicks >= 6 ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            {CreateBrowserIcon(parser(userAgent).browser.name)}
            &nbsp; [{parser(userAgent).browser.name}]
          </Typography>
        </Box>

        <Button
          variant="outlined"
          onClick={() => deleteIpBlocked(id)}
          sx={{
            width: '5%',
            borderColor: '#DD4913',
            color: '#DD4913',
            '&:hover': { borderColor: '#DD4913', bgcolor: '#DD4913', color: 'white' }
          }}
        >
          Excluir
        </Button>
      </Box>
    </Box>
  );
}
