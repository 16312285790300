import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import useConfigsStore from '../../features/stores/settings';

export default function AtivarSslDialog({ id, isOpen, handleClose }) {
  const [url, setUrl] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [text, setText] = React.useState('Por favor aguarde.');

  const [desbloqueando, setDesbloqueando] = React.useState(false);
  const [finish, setFinish] = React.useState(false);

  const [ip, setIp] = React.useState('');

  const { unlockIP } = useConfigsStore();

  const handleUnlock = async () => {
    setDesbloqueando(true);
    await unlockIP(ip);
    setTimeout(() => {
      setDesbloqueando(false);
      setFinish(true);
    }, 3000);
  };

  const handleReset = () => {
    setDesbloqueando(false);
    setFinish(false);
    setIp('');
    handleClose();
  };

  function MakeLoadingText() {
    return (
      <div>
        {!finish ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress
              sx={{
                marginTop: 3
              }}
            />
            <Typography
              sx={{
                marginTop: 1
              }}
            >
              Aguarde...
            </Typography>
          </Stack>
        ) : (
          <Typography>IP {ip} desbloqueado do servidor</Typography>
        )}
      </div>
    );
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Entre com o IP que deseja desbloquear</DialogTitle>
        <DialogContent>
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {desbloqueando || finish ? (
                <MakeLoadingText />
              ) : (
                <TextField
                  sx={{
                    width: '100%',
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="Endereço de IP"
                  variant="outlined"
                  onChange={(e) => setIp(e.target.value)}
                  value={ip}
                />
              )}
            </Box>
          </Stack>
        </DialogContent>
        {desbloqueando || finish ? (
          <DialogActions>
            <Button autoFocus disabled={!finish} onClick={handleReset}>
              OK
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancelar
            </Button>
            <Button autoFocus onClick={handleUnlock}>
              Confirmar
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
