import { useEffect, useState } from 'react';
import { Stack, Button, Tooltip, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useSettingsStore from '../features/stores/settings';
import useUserStore from '../features/stores/user';

import useAdsClickStore from '../features/stores/ads-clicks';

export default function Chips() {
  const [open, setOpen] = useState(false);
  const {
    settings,
    fetchSettings,
    walletsUser,
    findAllWalletsAndUsersByUserID,
    walletsUserbyUser,
    fetchSettingsWalletUser
  } = useSettingsStore();
  const { adsClicks } = useAdsClickStore();
  const { userData } = useUserStore();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    findAllWalletsAndUsersByUserID(userData[0].sub);
  }, []);

  return (
    <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'center', pb: 1 }}>
      {walletsUserbyUser.map((wallet, index) => (
        <CopyToClipboard key={index} text={wallet.wallet.wallet}>
          <Tooltip title={wallet.wallet.wallet}>
            <Button
              variant="outlined"
              sx={{
                height: 20,
                borderColor: getRandomColor(),
                color: '#0B831F',
                '&:hover': { bgcolor: '#48B61F', color: 'white' }
              }}
            >
              {wallet.wallet.wallet_name}
            </Button>
          </Tooltip>
        </CopyToClipboard>
      ))}
    </Stack>
  );
}
