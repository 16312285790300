import { useContext, useCallback, useEffect } from 'react';
// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
import { AiOutlineFieldTime } from 'react-icons/ai';
import * as parser from 'ua-parser-js';
import ptLocale from 'date-fns/locale/pt-BR';
import { formatDistance } from 'date-fns';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { BsMouseFill } from 'react-icons/bs';
import { BiBlock } from 'react-icons/bi';
import { MdRouter, MdLocationCity, MdDns } from 'react-icons/md';
import { FaWolfPackBattalion } from 'react-icons/fa';
import { GiWorld } from 'react-icons/gi';
import { IoLanguage } from 'react-icons/io5';
import Scrollbar from '../../Scrollbar';
import { CreateAdsClickColor } from '../../../utils/ui/createColors';
import { CreateOsIcon, CreateBrowserIcon } from '../../../utils/ui/createIcons';
import { SocketContext } from '../../../services/socket/socket';

import useAdsClicks from '../../../features/stores/ads-clicks';

// ----------------------------------------------------------------------

AppAdsClicks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#FF0000' : '#FF0000'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));

export default function AppAdsClicks({ title, subheader, list, ...other }) {
  const { generateIpsBlockTxt, deleteAll, adsClicks, updateClicksCount, updateStatus } =
    useAdsClicks();
  const socket = useContext(SocketContext);

  const handleUpdateClicks = useCallback(
    (data) => {
      updateClicksCount(data);
    },
    [updateClicksCount]
  );

  const handleUpdateStatus = useCallback(
    (data) => {
      updateStatus(data);
    },
    [updateStatus]
  );

  useEffect(() => {
    socket.on('adsClick', (data) => {
      handleUpdateClicks(data);
      handleUpdateStatus(data);
    });

    return () => {
      socket.off('adsClick');
    };
  }, [handleUpdateClicks, handleUpdateStatus, socket]);

  return (
    <Card {...other}>
      <CardHeader title={`${title} ${adsClicks.length}`} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={1.5} sx={{ p: 1 }}>
          {list.map((news, index) => (
            <NewsItem key={index} news={news} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string
  })
};

function NewsItem({ news }) {
  const { id, ip, lang, referer, state, dns, clicks, userAgent, updatedAt, city, status, country } =
    news;

  const { setBlock, generateIpsBlockTxt, deleteClick } = useAdsClicks();

  const handleSetBlock = async (id, status) => {
    await setBlock(id, !status);
  };

  const downloadIPs = () => {
    generateIpsBlockTxt();
  };

  const deleteAdsClick = (id) => {
    deleteClick(id);
  };

  return (
    <Box
      key={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: 0.5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            fontSize: 12,
            fontWeight: 'bold',
            color: `${status === true ? 'white' : '#04297A'}`,
            height: '100%',
            width: '13%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: `${CreateAdsClickColor(status)}`,
            borderRadius: 0.5,
            p: 1
          }}
        >
          {clicks > 3 ? <FaWolfPackBattalion /> : <BsMouseFill />}
          &nbsp;{clicks}&nbsp; {clicks > 3 ? 'Attack' : 'Click'}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            fontSize: 12,
            fontWeight: 'bold',
            color: `${status === true ? 'red' : '#04297A'}`,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1
          }}
        >
          <MdRouter size={15} />
          &nbsp;IP: {ip}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            fontSize: 12,
            fontWeight: 'bold',
            color: `${status === true ? 'red' : '#04297A'}`,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1
          }}
        >
          <GiWorld size={15} />
          &nbsp;País: {country}
        </Typography>

        <Typography
          sx={{
            display: 'flex',
            fontSize: 12,
            fontWeight: 'bold',
            color: `${status === true ? 'red' : '#04297A'}`,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1
          }}
        >
          <MdDns size={15} />
          &nbsp;DNS: {dns}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            fontSize: 11,
            fontWeight: 'bold',
            color: `${status === true ? 'red' : '#04297A'}`,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1
          }}
        >
          <MdDns size={15} />
          &nbsp;Referer: {referer}
        </Typography>

        <Box
          sx={{
            width: '10%'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleSetBlock(id, status)}
            sx={{
              height: 25,
              width: '9%',
              borderColor: `${status ? '#43BF2F' : '#DD4913'}`,
              color: `${status ? '#43BF2F' : '#DD4913'}`,
              '&:hover': {
                borderColor: `${status ? '#43BF2F' : '#DD4913'}`,
                bgcolor: `${status ? '#43BF2F' : '#DD4913'}`,
                color: 'white'
              }
            }}
          >
            {status ? 'Unblock' : 'Block'}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          height: '40%',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderRadius: 0.5,
          textAlign: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '94%',
            backgroundColor: `${CreateAdsClickColor(status)}`,
            borderRadius: 0.5
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${status === true ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            <AiOutlineFieldTime size={15} />
            {formatDistance(Date.parse(updatedAt), new Date(), {
              locale: ptLocale
            })}{' '}
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${status === true ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            {CreateOsIcon(parser(userAgent).os.name)}
            &nbsp; [{parser(userAgent).os.name}]
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${status === true ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            {CreateBrowserIcon(parser(userAgent).browser.name)}
            &nbsp; [{parser(userAgent).browser.name}]
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${status === true ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            <IoLanguage size={15} />
            &nbsp; Lang: [{lang}]
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${status === true ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            <MdLocationCity size={15} />
            &nbsp; Cidade: {city}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontSize: 9,
              fontWeight: 'bold',
              color: `${status === true ? 'white' : '#04297A'}`,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              p: 1
            }}
          >
            <MdLocationCity size={15} />
            &nbsp; Estado: {state}
          </Typography>
        </Box>

        <Button
          variant="outlined"
          onClick={() => deleteAdsClick(id)}
          sx={{
            height: 35,
            width: '5%',
            borderColor: '#DD4913',
            color: '#DD4913',
            '&:hover': { borderColor: '#DD4913', bgcolor: '#DD4913', color: 'white' }
          }}
        >
          Excluir
        </Button>
      </Box>
    </Box>
  );
}
