import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { signin } from '../../services/auth/auth';

const store = (set, get) => ({
  authData: [],
  signin: async (nick, password) => {
    const response = await signin(nick, password);
    sessionStorage.setItem('userData', JSON.stringify(response.data));
    set({ userData: response.data });
  },
  addAuthData: (user) => {
    set({ authData: [...new Set([...get().authData, user])] });
  }
});

const useStore = create(devtools(store));

export default useStore;
