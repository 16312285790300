import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import usePagesStore from '../../../features/stores/dominios';

export default function DeleteSslDialog({ id, isOpen, handleCloseDelete, domain }) {
  const [url, setUrl] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [text, setText] = React.useState('Por favor aguarde.');
  const { deleteDominio } = usePagesStore();

  const handleDeleteSSL = async (id) => {
    setShowDialog(true);
    await deleteDominio(id);
    setShow(false);
    setTimeout(() => {
      setText('SSL ativado com sucesso!');
      setShowDialog(false);
      handleCloseDelete();
    }, 15000);
  };

  function MakeDialog() {
    return (
      <div>
        <Dialog
          open={showDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Ativando SSL 🔒</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>Ativação em andamento...</Typography>
              <Typography>{text}</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      {show ? (
        <Dialog
          open={isOpen}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Deletar SSL 🔒</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                sx={{
                  width: 500,
                  mt: 1
                }}
              >
                Deseja remover o domínio {domain}?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleCloseDelete}>
              Cancelar
            </Button>
            <Button onClick={() => handleDeleteSSL(id)} autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <MakeDialog />
      )}
    </div>
  );
}
