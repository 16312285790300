import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { BiEditAlt, BiBookBookmark } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GiSheikahEye } from 'react-icons/gi';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// ----------------------------------------------------------------------
import AppDeleteServerBotDialog from '../../app/AppDeleteServerBotDialog';
import AppPermissionsBotDialogBOT from '../../app/AppPermissionsBotDialogBOT';
import EditServerDialogBot from '../../../../layouts/dashboard/EditServerDialogBot';

import ServerProxyBotByServerDialog from '../../../../layouts/dashboard/ServerProxyBotByServerDialog';

import useServerStore from '../../../../features/stores/server';
import useBotStore from '../../../../features/stores/bot';
import useUserStore from '../../../../features/stores/user';

export default function ServerMoreMenuBOT({ id, name, ip, api }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);

  const { fetchProxyesByServer } = useServerStore();
  const { fetchAllServersAndUsersByServerBOTID } = useBotStore();
  const { fetchUsersBOT } = useUserStore();

  const [openEditUser, setOpenEditUser] = useState(false);
  const [openProxyByServer, setOpenSProxyByServer] = useState(false);
  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const handleOpenBotPermissions = async () => {
    await fetchAllServersAndUsersByServerBOTID(id);
    await fetchUsersBOT();
    setOpenPermissions(true);
  };

  const handleCloseBotPermissions = () => {
    setOpenPermissions(false);
  };

  const handleCloseProxyByServer = () => {
    setOpenSProxyByServer(false);
  };

  const handleOpenProxyByServer = async () => {
    await fetchProxyesByServer(id);
    setOpenSProxyByServer(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <AppDeleteServerBotDialog id={id} name={name} isOpenDialog={open} handleClose={handleClose} />

      <ServerProxyBotByServerDialog
        name="ATTACK"
        id={id}
        isopenProxyBot={openProxyByServer}
        handleClickCloseProxyPesquisa={handleCloseProxyByServer}
      />

      <EditServerDialogBot
        id={id}
        name={name}
        ip={ip}
        api={api}
        isOpenEditUser={openEditUser}
        handleCloseEditUser={handleCloseEditUser}
      />

      <AppPermissionsBotDialogBOT
        id={id}
        isOpen={openPermissions}
        handleCloseBotPermissions={handleCloseBotPermissions}
      />

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
          <ListItemIcon>
            <RiDeleteBinLine width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpenEditUser}>
          <ListItemIcon>
            <BiEditAlt width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpenProxyByServer}>
          <ListItemIcon>
            <GiSheikahEye width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Atualizar Proxy" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpenBotPermissions}>
          <ListItemIcon>
            <BiBookBookmark width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Permissões" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
