import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, Box, TextField, Autocomplete, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

import useAnalyticsStore from '../../../features/stores/analytics';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

const CHART_DATA = [4344, 5435, 1443, 4443];

export default function AppCurrentVisits({ title }) {
  const theme = useTheme();
  const { fetchAnalytics, analytics } = useAnalyticsStore();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchAnalytics();
  }, [fetchAnalytics]);

  const handleSelectWallet = (val) => {
    setData(analytics.filter((walletData) => walletData.wallet === val));
  };

  const labels = analytics.map((i) => i.wallet);
  const paises = data.map((i) => i.analytics_clicks.map((a) => a.country));
  const CLICKS = data.map((i) => i.analytics_clicks.map((a) => a.click));

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.error.main
    ],
    labels: paises,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    }
  });

  return (
    <Card>
      {/* <CardHeader title={title} /> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          mt: 1
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>Mais acessados</Typography>
        <Autocomplete
          onChange={(event, value) => handleSelectWallet(value)}
          disablePortal
          id="combo-box-demo"
          options={labels}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Wallets" />}
        />
      </Box>
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={CLICKS} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  );
}
