import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  swaps: [],
  fetchSwaps: async () => {
    const response = await api.get('/api/swap', {
      headers: authHeader()
    });
    set({ swaps: response.data });
  },
  deleteDominio: async (id) => {
    await api.delete(`/api/pages/${id}`, { headers: authHeader() });
    set({
      swaps: get().dominios.filter((dominio) => dominio.id !== id)
    });
  },
  addWallet: async (name, rede, addr) => {
    const response = await api.post('/api/swap', { name, rede, addr }, { headers: authHeader() });

    set({ swaps: [...new Set([...get().swaps, response.data])] });
  },
  ativarSsl: async (id, url) => {
    const response = await api.patch(`/api/pages/${id}`, { url }, { headers: authHeader() });

    set((state) => ({
      dominios: state.dominios.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            url: response.data.url
          };
        }
        return item;
      })
    }));
  },
  onOffWallet: async (id, status) => {
    const response = await api.patch(`/api/pages/${id}`, { status }, { headers: authHeader() });

    set((state) => ({
      dominios: state.dominios.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  }
});
const useStore = create(devtools(store));

export default useStore;
