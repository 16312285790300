// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
import { formatDistance } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { AiOutlineDownload } from 'react-icons/ai';
import useStore from '../../../features/stores/wallet-checker';
import Scrollbar from '../../Scrollbar';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  const { generateTxt } = useStore();

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((news, index) => (
            <NewsItem key={index} news={news} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          width: 360,
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          textAlign: 'right'
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: '#0B831F',
            color: '#0B831F',
            '&:hover': { bgcolor: '#48B61F', color: 'white' }
          }}
          onClick={() => generateTxt('money')}
        >
          Baixar com money <AiOutlineDownload />
        </Button>

        <Button
          variant="outlined"
          sx={{
            borderColor: '#DD4913',
            color: '#DD4913',
            '&:hover': { borderColor: '#DD4913', bgcolor: '#DD4913', color: 'white' }
          }}
          onClick={() => generateTxt('lisa')}
        >
          Baixar lisas
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string
  })
};

function NewsItem({ news }) {
  const { image, title, description, date, balance, lisa } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="img"
        alt={title}
        src={image}
        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
      />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" noWrap>
          {title}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 11 }} noWrap>
          {description}
        </Typography>
        <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
          Saldo: $ {balance.toFixed(2)}
        </Typography>
      </Box>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
          {formatDistance(Date.parse(date), new Date(), {
            locale: ptLocale
          })}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pr: 3,
            flexShrink: 0,
            color: `${lisa ? '#FC2B2B' : '#218717'}`,
            fontWeight: 'bold',
            fontSize: 16
          }}
        >
          {lisa ? 'LISA' : 'Money'}
        </Typography>
      </Stack>
    </Stack>
  );
}
