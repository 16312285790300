import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// components
import Page from './components/Page';
import ThemeConfig from './theme';
import MHidden from './components/@material-extend/MHidden';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
// ----------------------------------------------------------------------

export default function AppNoBrowser({ browser }) {
  return (
    <ThemeConfig>
      <RootStyle
        title="Login | Team TradingView"
        sx={{
          backgroundColor: '#262924'
        }}
      >
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom color="#A9DF6A">
                Hey! seu navegador {browser} não é compatível com essa versão do software!
              </Typography>

              <Typography gutterBottom color="#A9DF6A">
                Navegador compatível: Google Chrome. <br /> Seu navegador: {browser}.
              </Typography>
              <img src="/static/images/ricky.png" alt="login" />
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </ThemeConfig>
  );
}
