import create from 'zustand';
import { devtools } from 'zustand/middleware';
import authHeader from '../../services/auth/authHeader';
import api from '../../services/api/api';

const store = (set, get) => ({
  ipsBlockeds: [],
  fetchBlockedIps: async () => {
    const response = await api.get('/api/ads-click/blocked/all', {
      headers: authHeader()
    });

    set({ ipsBlockeds: response.data });
  },

  deleteBlocked: async (id) => {
    await api.delete(`/api/ads-click/blocked/${id}`, { headers: authHeader() });
    set({
      ipsBlockeds: get().ipsBlockeds.filter((click) => click.id !== id)
    });
  },

  deleteAll: async () => {
    await api.delete('/api/ads-click/blocked/all', { headers: authHeader() });
    set({
      ipsBlockeds: []
    });
  },

  deleteMenor5: async () => {
    const response = await api.delete('/api/ads-click/blocked/menorclick', {
      headers: authHeader()
    });
  },

  blockAttack: async (id) => {
    const response = await api.put(
      '/api/operables/blockatk',
      { id },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      ipsBlockeds: state.ipsBlockeds.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            is_block: response.data.is_block
          };
        }
        return item;
      })
    }));
  },

  addAccess: (access) => {
    set({ ipsBlockeds: [...new Set([...get().ipsBlockeds, access])] });
  },

  updateClicksCount: (payload) => {
    set((state) => ({
      ipsBlockeds: state.ipsBlockeds.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            clicks: payload.clicks
          };
        }
        return item;
      })
    }));
  },

  updateButtonBlock: (payload) => {
    set((state) => ({
      ipsBlockeds: state.ipsBlockeds.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            is_block: payload.is_block
          };
        }
        return item;
      })
    }));
  },

  generateIpsBlockTxt: (args) => {
    const blocks = get().ipsBlockeds.filter((r) => r.clicks > 5);

    const element = document.createElement('a');
    let file = '';
    let filename = '';

    filename = 'blocked';
    blocks.forEach((item) => {
      file += `${item.ip_addr}\n`;
    });

    const blob = new Blob([file]);

    element.href = URL.createObjectURL(blob);
    element.download = `ips-${filename}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
});

const useStore = create(devtools(store));

export default useStore;
