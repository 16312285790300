import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { GiHouseKeys } from 'react-icons/gi';
import { BiUser } from 'react-icons/bi';
import { FaServer } from 'react-icons/fa';
// material
import {
  Card,
  Table,
  Stack,
  Box,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Link
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  AddUserDialog,
  AddUserDialogBOT,
  DeleteUserDialog,
  UserSettingsListHead,
  UserSettingsListToolbar,
  UserSettingsMoreMenu
} from '../components/_dashboard/bot/users';

import {
  AddKeyDialog,
  BotKeysListHead,
  BotKeysListToolbar,
  BotKeysMoreMenu
} from '../components/_dashboard/bot/keys';
//
import AddAdsDialog from '../components/_dashboard/bot/components/AddAdsDialog';
import AddServerDialog from '../components/_dashboard/bot/components/AddServerDialog';
import useBotStore from '../features/stores/bot';
import useUserStore from '../features/stores/user';
import useServerStore from '../features/stores/server';

// ----------------------------------------------------------------------

const TABLE_HEAD_KEYS = [
  { id: 'chave', label: 'Chave', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'acao', label: 'Ação', alignRight: false },
  { id: '' }
];

const TABLE_HEAD_USERS = [
  { id: 'nick', label: 'Nick', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'acao', label: 'Ação', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilterUser(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nick.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterKey(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_key) => _key.chave.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BotSettings() {
  const [serverForAttack, setServerForAttack] = useState('');
  const [urlForAttack, setURLForAttack] = useState('');
  const [pageKey, setPageKey] = useState(0);
  const [pageUser, setPageUser] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selectedKey, setSelectedKey] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [orderBy, setOrderBy] = useState('name');

  const [filterUser, setFilterUser] = useState('');
  const [filterKey, setFilterKey] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { attack, setAttack, fetchKeys, keys, changeKeyStatus } = useBotStore();
  const { fetchUsers, usersBOT, banUserBot, fetchUsersBOT } = useUserStore();
  const { fetchservers, servers, editServer } = useServerStore();

  useEffect(() => {
    fetchKeys();
    fetchUsers();
    fetchservers();
    fetchUsersBOT();
  }, [fetchKeys]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClickKey = (event) => {
    if (event.target.checked) {
      const newSelecteds = attack.map((n) => n.chave);
      setSelectedKey(newSelecteds);
      return;
    }
    setSelectedKey([]);
  };

  const handleSelectAllClickUser = (event) => {
    if (event.target.checked) {
      const newSelecteds = keys.map((n) => n.nick);
      setSelectedUser(newSelecteds);
      return;
    }
    setSelectedUser([]);
  };

  const handleClickKey = (event, chave) => {
    const selectedIndex = selectedKey.indexOf(chave);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedKey, chave);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedKey.slice(1));
    } else if (selectedIndex === selectedKey.length - 1) {
      newSelected = newSelected.concat(selectedKey.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedKey.slice(0, selectedIndex),
        selectedKey.slice(selectedIndex + 1)
      );
    }
    setSelectedKey(newSelected);
  };

  const handleClickUser = (event, nick) => {
    const selectedIndex = selectedUser.indexOf(nick);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUser, nick);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUser.slice(1));
    } else if (selectedIndex === selectedUser.length - 1) {
      newSelected = newSelected.concat(selectedUser.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUser.slice(0, selectedIndex),
        selectedUser.slice(selectedIndex + 1)
      );
    }
    setSelectedUser(newSelected);
  };

  const handleChangePageKey = (event, newPage) => {
    setPageKey(newPage);
  };

  const handleChangePageUser = (event, newPage) => {
    setPageUser(newPage);
  };

  const handleChangeRowsPerPageKey = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageKey(0);
  };

  const handleChangeRowsPerPageUser = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageUser(0);
  };

  const handleFilterByUser = (event) => {
    setFilterUser(event.target.value);
  };

  const handleFilterByKey = (event) => {
    setFilterKey(event.target.value);
  };

  const emptyRowsKeys = pageKey > 0 ? Math.max(0, (1 + pageKey) * rowsPerPage - keys.length) : 0;
  const emptyRowsUser = pageUser > 0 ? Math.max(0, (1 + pageUser) * rowsPerPage - keys.length) : 0;

  const filteredUsers = applySortFilterUser(usersBOT, getComparator(order, orderBy), filterUser);
  const filteredKeys = applySortFilterKey(keys, getComparator(order, orderBy), filterKey);

  const isUserNotFound = filteredKeys.length === 0;

  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);

  // Open addkeys
  const [openAddKeys, setOpenAddKeys] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);

  const [openAddServer, setOpenAddServer] = useState(false);

  const [maxClicks, setMaxClicks] = useState('');
  const [serverID, setServerID] = useState(null);

  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenAddKeys = (e) => {
    setOpenAddKeys(true);
  };

  const handleClickOpenAddUser = (e) => {
    setOpenAddUser(true);
  };

  const handleCloseAddKeys = () => {
    setOpenAddKeys(false);
  };

  const handleCloseAddUser = () => {
    setOpenAddUser(false);
  };

  const handleChangeServer = (event) => {
    setServerForAttack(event.target.value);
  };

  const handleSetClicks = (e, clicks, id) => {
    e.preventDefault();
    setServerID(id);
    setMaxClicks(clicks);
  };

  const handleChangeKeyStatus = async (event, id, status) => {
    event.preventDefault();
    await changeKeyStatus(id, status);
  };

  const handleSetBanUser = async (event, id, status) => {
    event.preventDefault();
    await banUserBot(id, status);
  };

  const handleOpenAddServer = async () => {
    setOpenAddServer(true);
  };
  const handleCloseAddServer = async () => {
    setOpenAddServer(false);
  };

  const handleUpdateClicksServer = async () => {
    await editServer(serverID, maxClicks);
  };

  return (
    <Page title="Painel | Config">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            [BOT - Configurações]
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '20%'
            }}
          >
            <Button
              component={RouterLink}
              to="/dashboard/bot"
              variant="contained"
              onClick={handleClickOpen}
            >
              <IoMdArrowRoundBack /> &nbsp; Voltar
            </Button>
            <Button
              component={RouterLink}
              to="/dashboard/bot-servers"
              variant="contained"
              onClick={handleClickOpen}
            >
              <FaServer /> &nbsp; Servidores
            </Button>
          </Box>
        </Stack>
        <Card
          sx={{
            display: 'flex',
            padding: 3,
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h7" gutterBottom>
              Configurações dos BOT's
            </Typography>
          </Stack>
          <Stack
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <FormControl fullWidth sx={{ width: '30%' }}>
              <InputLabel id="demo-simple-select-label">Servidor</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={serverForAttack}
                label="Servidor"
                onChange={handleChangeServer}
              >
                {servers.map((server, index) => (
                  <MenuItem
                    key={index}
                    value={server.id}
                    onClick={(e) => handleSetClicks(e, server.clicks, server.id)}
                  >
                    Server #{server.id} - {server.tipo}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{' '}
            <TextField
              id="standard-basic"
              label="Qtde. max de clicks"
              variant="outlined"
              sx={{ width: '50%' }}
              value={maxClicks}
              onChange={(e) => setMaxClicks(e.target.value)}
            />
            <Button variant="contained" onClick={handleUpdateClicksServer}>
              Atualizar
            </Button>
          </Stack>
        </Card>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {/* palavras chave */}
          <Card
            sx={{
              marginTop: 5,
              width: '49%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                p: 3,
                alignContent: 'center'
              }}
            >
              <Typography variant="h7" gutterBottom>
                Chaves de pesquisa &nbsp;
              </Typography>
              <GiHouseKeys size={20} />
            </Stack>
            <BotKeysListToolbar
              numSelected={selectedKey.length}
              filterName={filterKey}
              onFilterName={handleFilterByKey}
              placeholder="Pesquisar chaves..."
              onOpenAddKeys={handleClickOpenAddKeys}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  <BotKeysListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_KEYS}
                    rowCount={keys.length}
                    numSelected={selectedKey.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClickKey}
                  />
                  <AddKeyDialog isOpen={openAddKeys} handleClose={handleCloseAddKeys} />
                  <TableBody>
                    {filteredKeys
                      .slice(pageKey * rowsPerPage, pageKey * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const isItemSelected = selectedKey.indexOf(row.chave) !== -1;
                        return (
                          <TableRow
                            hover
                            key={row.id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClickKey(event, row.chave)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase'
                                  }}
                                >
                                  {row.chave}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left" scope="row" padding="none">
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  color: `${row.status === 0 ? 'red' : 'green'}`,
                                  width: 150
                                }}
                              >
                                {row.status === 0 ? 'Off' : 'On'}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Button
                                onClick={(e) =>
                                  handleChangeKeyStatus(e, row.id, row.status === 0 ? 1 : 0)
                                }
                                variant="contained"
                                sx={{
                                  fontSize: 12,
                                  backgroundColor: `${row.status === 0 ? 'green' : 'yellow'}`,
                                  color: `${row.status === 0 ? 'white' : 'black'}`,
                                  width: 100
                                }}
                              >
                                {row.status === 0 ? 'Ativar' : 'Desativar'}
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <BotKeysMoreMenu id={row.id} chave={row.chave} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRowsKeys > 0 && (
                      <TableRow style={{ height: 53 * emptyRowsKeys }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterKey} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={keys.length}
              rowsPerPage={rowsPerPage}
              page={pageKey}
              onPageChange={handleChangePageKey}
              onRowsPerPageChange={handleChangeRowsPerPageKey}
            />
          </Card>

          {/* Usuários */}
          <Card
            sx={{
              marginTop: 5,
              width: '49%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                p: 3,
                alignContent: 'center'
              }}
            >
              <Typography variant="h7" gutterBottom>
                Usuários do BOT&nbsp;
              </Typography>
              <BiUser size={20} />
            </Stack>
            <UserSettingsListToolbar
              numSelected={selectedUser.length}
              filterName={filterUser}
              onFilterName={handleFilterByUser}
              placeholder="Pesquisar usuários..."
              onOpenAddUser={handleClickOpenAddUser}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  <UserSettingsListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_USERS}
                    rowCount={keys.length}
                    numSelected={selectedUser.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClickKey}
                  />

                  <AddUserDialogBOT isOpen={openAddUser} handleClose={handleCloseAddUser} />

                  <TableBody>
                    {filteredUsers
                      .slice(pageUser * rowsPerPage, pageUser * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const isItemSelected = selectedUser.indexOf(row.nick) !== -1;
                        return (
                          <TableRow
                            hover
                            key={row.id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClickUser(event, row.nick)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  sx={{
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {row.nick}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left" scope="row" padding="none">
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  color: `${row.isBan ? 'red' : 'green'}`,
                                  width: 150
                                }}
                              >
                                {row.isBan ? 'Banido' : 'Ativo'}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Button
                                onClick={(e) => handleSetBanUser(e, row.id, !row.isBan)}
                                variant="contained"
                                sx={{
                                  fontSize: 12,
                                  backgroundColor: `${row.isBan ? 'green' : 'red'}`,
                                  color: 'white',
                                  width: 100
                                }}
                              >
                                {row.isBan ? 'Desbanir' : 'Banir'}
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <UserSettingsMoreMenu id={row.id} nick={row.nick} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRowsUser > 0 && (
                      <TableRow style={{ height: 53 * emptyRowsUser }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterUser} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersBOT.length}
              rowsPerPage={rowsPerPage}
              page={pageUser}
              onPageChange={handleChangePageUser}
              onRowsPerPageChange={handleChangeRowsPerPageUser}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
