import React from 'react';
import socketio from 'socket.io-client';
import { SOCKET_URL } from '../api/api';
import authHeaderSocket from './authHeaderSocket';

const socketOptions = {
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: authHeaderSocket()
      }
    }
  }
};

export const socket = socketio.io(SOCKET_URL, socketOptions);
export const SocketContext = React.createContext();
