import { useEffect, useState } from 'react';
import { filter, merge } from 'lodash';

import { formatDistance, intlFormat, getHours, getMinutes } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
// material
import {
  TableContainer,
  Card,
  Typography,
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Box,
  TablePagination
} from '@mui/material';
// utils
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { fNumber } from '../../../utils/formatNumber';
import { CreateUserStatusColor } from '../../../utils/ui/createColors';
//
import { UserMoreMenu, UserListHead, UserListToolbar } from '../user';
import useUserStore from '../../../features/stores/user';
import useAppSettings from '../../../features/stores/settings';
import AppDeleteUserDialog from './AppDeleteUserDialog';
import AddUserDialog from '../../../layouts/dashboard/AddUserDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nick', alignRight: false },
  { id: 'company', label: 'Status', alignRight: false },
  { id: 'role', label: 'Último login', alignRight: false },
  { id: 'ip', label: 'Endereço IP', alignRight: false },
  { id: 'ativo', label: 'Ativo', alignRight: false },
  { id: 'bot', label: 'Bot', alignRight: false },
  { id: 'acao', label: 'Ação', alignRight: false }
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nick.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AppViewLoginLogsRow() {
  const { enableMultiOperador, op, fetchConfig, fetchSettingsWalletUser } = useAppSettings();
  const { users, banUser, userData, setBotAcess } = useUserStore();
  const [id, setID] = useState(null);
  const [nick, setNick] = useState('');
  const [open, setOpen] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEnableMultiOperador = async (id, enabled) => {
    enableMultiOperador(id, enabled);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const setBanUser = (id, status) => {
    banUser(id, !status);
  };

  const handleSetBotAcess = (id, status) => {
    setBotAcess(id, !status);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteUserNick = async (id, nick) => {
    setID(id);
    setNick(nick);
    handleClickOpen();
  };

  useEffect(() => {}, []);

  return (
    <Card>
      <UserListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={users.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />

            <TableBody>
              {userData
                ? filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox" />
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row.nick}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                width: 70,
                                borderRadius: 4,
                                color: 'white',
                                fontSize: 14,
                                backgroundColor: `${CreateUserStatusColor(row.status)}`
                              }}
                            >
                              {row.status}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                fontSize: 13,
                                width: 160
                              }}
                            >
                              <AiOutlineFieldTime />
                              {intlFormat(Date.parse(row.last_login), new Date(), {
                                locale: ptLocale
                              })}
                              &nbsp;
                              {getHours(Date.parse(row.last_login), new Date(), {
                                locale: ptLocale
                              })}
                              :
                              {getMinutes(Date.parse(row.last_login), new Date(), {
                                locale: ptLocale
                              })}
                              h
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                fontSize: 13,
                                width: 160
                              }}
                            >
                              {row.ip}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              onClick={() => setBanUser(row.id, row.isBan)}
                              sx={{
                                height: 25,
                                borderColor: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                color: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                '&:hover': {
                                  borderColor: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                  bgcolor: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                  color: 'white'
                                }
                              }}
                            >
                              {row.isBan ? 'Desloquear' : 'Bloquear'}
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              onClick={() => handleSetBotAcess(row.id, row.isBotAcess)}
                              sx={{
                                height: 25,
                                borderColor: `${row.isBotAcess ? '#43BF2F' : '#DD4913'}`,
                                color: `${row.isBotAcess ? '#43BF2F' : '#DD4913'}`,
                                '&:hover': {
                                  borderColor: `${row.isBotAcess ? '#43BF2F' : '#DD4913'}`,
                                  bgcolor: `${row.isBotAcess ? '#43BF2F' : '#DD4913'}`,
                                  color: 'white'
                                }
                              }}
                            >
                              {row.isBotAcess ? 'Permitido' : 'Bloqueado'}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu id={row.id} nick={row.nick} />
                          </TableCell>
                        </TableRow>
                      );
                    })
                : filteredUsers
                    .filter((page) => page.status !== false)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row.nick) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.nick)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row.nick}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                width: 70,
                                borderRadius: 4,
                                color: 'white',
                                fontSize: 14,
                                backgroundColor: `${CreateUserStatusColor(row.status)}`
                              }}
                            >
                              {row.status}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              onClick={() => setBanUser(row.id, row.isBan)}
                              sx={{
                                height: 25,
                                borderColor: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                color: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                '&:hover': {
                                  borderColor: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                  bgcolor: `${row.isBan ? '#DD4913' : '#43BF2F'}`,
                                  color: 'white'
                                }
                              }}
                            >
                              {row.isBan ? 'Desloquear' : 'Bloquear'}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu id={row.id} nick={row.nick} />
                          </TableCell>
                        </TableRow>
                      );
                    })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
