import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  bots: [],
  attack: [],
  keys: [],
  userAndServer: [],
  serverUser: [],

  fetchAll: async () => {
    const response = await api.get('/api/bot', {
      headers: authHeader()
    });
    set({ bots: response.data });
  },

  fetchByUser: async (user) => {
    const response = await api.get(`/api/bot/user/${user}`, {
      headers: authHeader()
    });
    set({ bots: response.data });
  },

  addBot: async (responsavel, criador, url, isDefault) => {
    const response = await api.post(
      '/api/bot',
      { responsavel, criador, url, default: isDefault },
      { headers: authHeader() }
    );

    await set({ bots: [...new Set([...get().bots, response.data])] });
    window.location.reload();
  },

  deleteBot: async (id) => {
    const response = await api.delete(`/api/bot/${id}`, { headers: authHeader() });

    set({
      bots: get().bots.filter((bk1) => bk1.id !== response.data.id)
    });
    return 1;
  },

  deleteBots: async (arr) => {
    const response = await api.post(
      '/api/bot/bots/delete',
      {
        arr
      },
      { headers: authHeader() }
    );

    if (response.data.count > 0) {
      window.location.reload();
    }

    return 1;
  },

  handleUpdateAds: (payload) => {
    set((state) => ({
      bots: state.bots.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            status: Number(payload.status)
          };
        }
        return item;
      })
    }));
  },

  // CHAVES
  addKeys: async (key) => {
    const response = await api.post(
      '/api/bot/keys',
      { chave: key },
      {
        headers: authHeader()
      }
    );
    set({ keys: [...new Set([...get().keys, response.data])] });
  },

  fetchKeys: async () => {
    const response = await api.get('/api/bot/keys', {
      headers: authHeader()
    });
    set({ keys: response.data });
  },

  changeKeyStatus: async (id, status) => {
    const response = await api.put(
      `/api/bot/key/${id}`,
      { status },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      keys: state.keys.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },

  // ATTACK
  fetchAttack: async () => {
    const response = await api.get('/api/bot/attack', {
      headers: authHeader()
    });
    set({ attack: response.data });
  },

  setAttack: async (id, status) => {
    const response = await api.put(
      `/api/bot/attack/set/${id}`,
      { status },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      attack: state.attack.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },

  deleteAttack: async (id) => {
    await api.delete(`/api/bot/attack/${id}`, { headers: authHeader() });
    set({
      attack: get().attack.filter((atk) => atk.id !== id)
    });
  },

  deleteAttackWebSocket: async (payload) => {
    set({
      attack: get().attack.filter((atk) => atk.id !== payload.id)
    });
  },

  addAttackWebSocket: (payload) => {
    set({ attack: [...new Set([...get().attack, payload])] });
  },

  addAttack: async (url, server, status, nick) => {
    const response = await api.post(
      '/api/bot/attack',
      { url, server, status, responsavel: nick },
      { headers: authHeader() }
    );
    if (response.data.err) {
      alert(response.data.msg);
      return;
    }

    set({ attack: [...new Set([...get().attack, response.data])] });
  },

  handleUpdateClick: (payload) => {
    set((state) => ({
      attack: state.attack.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            click: payload.click
          };
        }
        return item;
      })
    }));
  },

  deleteKey: async (id) => {
    await api.delete(`/api/bot/key/${id}`, { headers: authHeader() });
    set({
      keys: get().keys.filter((key) => key.id !== id)
    });
  },

  // SERVIDORES
  setUserAndServerBot: async (id, userId) => {
    const response = await api.put(
      `/api/bot/usersonserver/${id}/user/${userId}`,
      {},
      {
        headers: authHeader()
      }
    );
    set({
      userAndServer: [...new Set([...get().userAndServer, response.data])]
    });
    set({
      serverUser: [...new Set([...get().serverUser, response.data])]
    });
  },

  fetchUsersonServer: async (id, userId) => {
    const response = await api.get(`/api/bot/usersonserver/all`, {
      headers: authHeader()
    });

    set({ serverUser: response.data });
  },

  fetchAllServersAndUsersByServerBOTID: async (id) => {
    const response = await api.get(`/api/bot/usersonserverbyserverid/${id}`, {
      headers: authHeader()
    });
    set({ userAndServer: response.data });
  },

  deleteUserOnServer: async (userID, serverID) => {
    const response = await api.delete(`/api/bot/usersonserver/${serverID}/user/${userID}`, {
      headers: authHeader()
    });

    window.location.reload();
  }
});

const useStore = create(devtools(store));

export default useStore;
