import * as React from 'react';
import { useEffect, useCallback } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { FcLock } from 'react-icons/fc';
import { GiHypersonicMelon } from 'react-icons/gi';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Box, Checkbox, Stack, Typography, Autocomplete, TextField, Button } from '@mui/material';
import { options } from 'numeral';
import useUserStore from '../../../features/stores/user';
import useServerBotStore from '../../../features/stores/bot';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function TaskItem({ id, nick, formik, checked }) {
  const { getFieldProps } = formik;

  const handleCheck = async (id) => {
    // await setUserAsOperador(id, !checked);
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ py: 0.75 }}>
      <FormControlLabel
        control={<Typography>&nbsp;</Typography>}
        label={
          <Typography
            variant="body2"
            sx={{
              fontSize: 16,
              width: 80,
              color: 'text.disabled',
              ...(checked && {
                color: 'black'
              })
            }}
          >
            &nbsp;
            {nick}
          </Typography>
        }
      />
    </Stack>
  );
}

function AppPermissionsBotDialogBOT(props) {
  const { usersBOT } = useUserStore();
  const { setUserAndServerBot, userAndServer, deleteUserOnServer } = useServerBotStore();
  const formik = useFormik({
    initialValues: {
      checked: [usersBOT[1]]
    },
    onSubmit: (values) => {}
  });

  const { handleSubmit } = formik;

  const setUserAndServer = async (evt, value) => {
    const lastUserID = value.slice(-1).pop();
    await setUserAndServerBot(props.id, lastUserID.id);
  };

  const handleDeleteLinkedUser = async (userId, serverID) => {
    await deleteUserOnServer(userId, props.id);
  };

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={props.handleCloseBotPermissions}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          width: 600,
          height: 500
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Permissões <FcLock />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quem pode acessar o servidor <strong>{props.name}</strong> ?
          </DialogContentText>
          <Autocomplete
            multiple
            id="tags-standard"
            options={usersBOT}
            onChange={setUserAndServer}
            getOptionLabel={(option) => option.nick}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Escolha um usuário" />
            )}
          />
        </DialogContent>

        <Box sx={{ px: 3, py: 1 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {userAndServer.map((userW, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                  <TaskItem
                    id={userW.user.id}
                    nick={userW.user.nick}
                    formik={formik}
                    checked={userW.isActive}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteLinkedUser(userW.user.id, userW.server.id)}
                    sx={{
                      height: 25,
                      width: '5%',
                      borderColor: '#DD4913',
                      color: '#DD4913',
                      '&:hover': { borderColor: '#DD4913', bgcolor: '#DD4913', color: 'white' }
                    }}
                  >
                    Excluir
                  </Button>
                </Box>
              ))}
            </Form>
          </FormikProvider>
        </Box>
      </Box>
    </Dialog>
  );
}

export default React.memo(AppPermissionsBotDialogBOT);
