import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { FaServer } from 'react-icons/fa';
import InputAdornment from '@mui/material/InputAdornment';

import useServerStore from '../../../../features/stores/server';

export default function AddServerDialogBOT({ isOpen, handleClose }) {
  const [tipo, setTipo] = React.useState();
  const [ip, setIP] = React.useState();
  const [maxClicks, setMaxClicks] = React.useState();

  const { createBotServers } = useServerStore();

  const handleAddNewServer = async () => {
    if (tipo && ip && maxClicks) {
      await createBotServers(ip, maxClicks, tipo);
      setTipo('');
      setIP('');
      setMaxClicks('');
      handleClose();
    } else {
      alert('Preencha todos os campos');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 200,
          justifyContent: 'space-between'
        }}
      >
        Novo Servidor <FaServer />{' '}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: 150,
                  mt: 1
                }}
                id="outlined-basic"
                label="Tipo"
                variant="outlined"
                onChange={(e) => setTipo(e.target.value)}
                value={tipo}
              />
              &nbsp;
              <TextField
                sx={{
                  width: 400,
                  mt: 1
                }}
                id="outlined-basic"
                label="IP"
                variant="outlined"
                onChange={(e) => setIP(e.target.value)}
                value={ip}
              />{' '}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: '100%',
                  mt: 1
                }}
                id="outlined-basic"
                label="Max de clicks"
                variant="outlined"
                onChange={(e) => setMaxClicks(e.target.value)}
                value={maxClicks}
              />{' '}
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancelar
        </Button>
        <Button autoFocus onClick={() => handleAddNewServer(tipo, ip, maxClicks)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
