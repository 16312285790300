import { filter } from 'lodash';
// material
import {
  Box,
  Container,
  Typography,
  Card,
  TableBody,
  TableCell,
  Button,
  TableContainer,
  Chip,
  Checkbox,
  TableRow,
  TablePagination,
  Table,
  Stack
} from '@mui/material';
// components
import DeleteIcon from '@mui/icons-material/Delete';
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ServerMoreMenuBOT,
  ServerListHeadBOT,
  ServerListToolbarBOT
} from '../components/_dashboard/bot/server';
// components

import useUserStore from '../features/stores/user';
import useServerStore from '../features/stores/server';
import useBotStore from '../features/stores/bot';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'ip', label: 'IP', alignRight: false },
  { id: 'clicks', label: 'Clicks', alignRight: false },
  { id: 'usuarios', label: 'Usuários', alignRight: false },
  { id: '' }
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ServersBot() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { userData, users } = useUserStore();
  const { serversBot, fetchBotServers } = useServerStore();
  const { serverUser, fetchUsersonServer } = useBotStore();

  useEffect(() => {
    fetchBotServers();
    fetchUsersonServer();
  }, [fetchBotServers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = serversBot.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - serversBot.length) : 0;

  const filteredUsers = applySortFilter(serversBot, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Bot | Servidores">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            [Servidores] BOT
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button component={RouterLink} to="/dashboard/bot-settings" variant="contained">
              <IoMdArrowRoundBack /> &nbsp; Voltar
            </Button>
          </Box>
        </Stack>

        <Card
          sx={{
            marginTop: 5
          }}
        >
          <ServerListToolbarBOT
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ServerListHeadBOT
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={serversBot.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {userData && userData[0].role === 'ADMIN'
                    ? filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.id)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    #{row.id}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  {row.ip}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  {row.clicks}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      width: 200
                                    }}
                                  >
                                    {serverUser
                                      .filter((servidor) => servidor.server.ip === row.ip)
                                      .map((item, index) => (
                                        <Chip
                                          variant="outlined"
                                          sx={{
                                            fontSize: 10,
                                            marginRight: 1,
                                            color: `${
                                              item.user.nick === 'mitnick' ? '#04297A' : 'black'
                                            }`,
                                            fontWeight: `${
                                              item.user.nick === 'mitnick' ? 'bold' : 'normal'
                                            }`,
                                            borderColor: `${
                                              item.user.nick === 'mitnick' ? '#04297A' : ''
                                            }`
                                          }}
                                          key={index}
                                          label={item.user.nick}
                                          // onClick={handleClick}
                                          // onDelete={() => handleDelete(item.user.id, row.id)}
                                          deleteIcon={<DeleteIcon sx={{ width: 15 }} />}
                                        />
                                      ))}
                                  </Box>
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <ServerMoreMenuBOT id={row.id} ip={row.ip} name={row.name} />
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : filteredUsers
                        .filter((page) => page.status !== false)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.name)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {row.id}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="subtitle2" noWrap>
                                  {row.ip} - {row.tipo}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <ServerMoreMenuBOT id={row.id} ip={row.ip} name={row.name} />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={serversBot.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
