import { useEffect, useState } from 'react';
import { filter } from 'lodash';

// material
import {
  TableContainer,
  Card,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Box,
  Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// utils
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import useSettingsStore from '../../../features/stores/settings';
import { WalletsListHead, WalletsListToolbar, WalletsMoreMenu } from '../wallets';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nome', label: 'Nome', alignRight: false },
  { id: 'endereco', label: 'Endereço', alignRight: false },
  { id: 'Operador', label: 'Operador', alignRight: false }
];
// ----------------------------------------------------------------------

function geradorKey(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    if (result.length === 15) {
      result += 'j';
    }
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_wallet) => _wallet.wallet_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AppWalletsRow() {
  const {
    fetchSettings,
    settings,
    addSetting,
    deleteSetting,
    walletsUserbyUserWallet,
    walletsUser
  } = useSettingsStore();
  const [id, setID] = useState(null);
  const [nick, setNick] = useState('');
  const [open, setOpen] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = settings.map((n) => n.wallet_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - settings.length) : 0;

  const filteredUsers = applySortFilter(settings, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // const setBanUser = (id, status) => {
  //   banUser(id, !status);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteUserNick = async (id, nick) => {
    setID(id);
    setNick(nick);
    handleClickOpen();
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return (
    <Card>
      <WalletsListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <WalletsListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={settings.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />

            <TableBody>
              {settings
                ? filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.wallet_name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox" />
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row.wallet_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                fontSize: 13,
                                width: 160
                              }}
                            >
                              {row.wallet.slice(0, 8)}...
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Box
                              sx={{
                                display: 'flex',
                                width: 200
                              }}
                            >
                              {walletsUser
                                .filter((wallet) => wallet.wallet.wallet_name === row.wallet_name)
                                .map((item, index) => (
                                  <Chip
                                    variant="outlined"
                                    sx={{
                                      fontSize: 10,
                                      marginRight: 1,
                                      color: `${
                                        item.user.nick === 'mitnick' ? '#04297A' : 'black'
                                      }`,
                                      fontWeight: `${
                                        item.user.nick === 'mitnick' ? 'bold' : 'normal'
                                      }`,
                                      borderColor: `${
                                        item.user.nick === 'mitnick' ? '#04297A' : ''
                                      }`
                                    }}
                                    key={index}
                                    label={item.user.nick}
                                    // onClick={handleClick}
                                    // onDelete={() => handleDelete(item.user.id, row.id)}
                                    deleteIcon={<DeleteIcon sx={{ width: 15 }} />}
                                  />
                                ))}
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <WalletsMoreMenu id={row.id} nick={row.nick} name={row.wallet_name} />
                          </TableCell>
                        </TableRow>
                      );
                    })
                : filteredUsers
                    .filter((page) => page.status !== false)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.wallet_name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row.wallet_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <WalletsMoreMenu id={row.id} nick={row.nick} />
                          </TableCell>
                        </TableRow>
                      );
                    })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={settings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
