import React from 'react';
// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../components/Page';

import ChipsBank from '../components/ChipsBank';

import { AppOperadorBanking } from '../components/_dashboard/app';
// ----------------------------------------------------------------------

export default function OperadorBank() {
  return (
    <Page title="Dashboard | Bank">
      <Container maxWidth="xl">
        <ChipsBank />
        <Grid item xs={12} md={12} lg={12}>
          <AppOperadorBanking />
        </Grid>
      </Container>
    </Page>
  );
}
