import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { SiGoogleads } from 'react-icons/si';

import useBotStore from '../../../../features/stores/bot';
import useUserStore from '../../../../features/stores/user';

export default function AddUserDialogBOT({ isOpen, handleClose }) {
  const [nick, setNick] = React.useState();
  const [password, setPassword] = React.useState();

  const { addNewUserBOT } = useUserStore();

  const handleAddNewUser = async () => {
    if (nick && password) {
      await addNewUserBOT(nick, password);
      setNick('');
      setPassword('');
      handleClose();
    } else {
      alert('Preencha todos os campos');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Novo Usuário do BOT</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                  sx={{
                    width: '35%',
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="Nick"
                  variant="outlined"
                  onChange={(e) => setNick(e.target.value)}
                  value={nick}
                />{' '}
                <TextField
                  sx={{
                    width: '60%',
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="password"
                  variant="outlined"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />{' '}
              </Box>
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={handleAddNewUser} autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
