import React from 'react';
// material
import { Grid } from '@mui/material';
//
import AppWeeklySales from './AppWeeklySales';
import AppTotalClients from './AppNewUsers';
import AppBroke from './AppItemOrders';
// ----------------------------------------------------------------------

function AppClients(props) {
  const withdrawed = props.clients.filter((item) => item.status === 'Yes');
  const broked = props.clients.filter((item) => item.status === 'Broke');
  const notWithdrawed = props.clients.filter((item) => item.status === 'No');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppTotalClients title="Total de clientes" value={props.clients.length} icon="👥" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppWeeklySales title="Com grana" value={withdrawed.length} icon="💰" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppBroke title="Lisas" value={broked.length} icon="💸" />
        </Grid>
      </Grid>
    </>
  );
}
export default React.memo(AppClients);
