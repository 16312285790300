import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import InputAdornment from '@mui/material/InputAdornment';

import { FaServer } from 'react-icons/fa';

import { useEffect } from 'react';
import useUserStore from '../../features/stores/user';
import useServerStore from '../../features/stores/server';

export default function EditServerDialog(props) {
  const { editServer } = useServerStore();

  const [name, setName] = React.useState('');
  const [ip, setIP] = React.useState('');
  const [api, setAPI] = React.useState('');

  const handleUpdateServer = async (name, ip, api) => {
    if (name && ip && api) {
      await editServer(props.id, name, ip, api);
      setName('');
      setIP('');
      setAPI('');
      props.handleCloseEditUser();
    } else {
      alert('Preencha todos os campos');
    }
  };

  useEffect(() => {
    setName(props.name);
    setIP(props.ip);
    setAPI(props.api);
  }, []);

  return (
    <Dialog
      open={props.isOpenEditUser}
      onClose={props.handleCloseEditUser}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 200,
          justifyContent: 'space-between'
        }}
      >
        Editar Servidor <FaServer />{' '}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: 150,
                  mt: 1
                }}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              &nbsp;
              <TextField
                sx={{
                  width: 400,
                  mt: 1
                }}
                id="outlined-basic"
                label="IP"
                variant="outlined"
                onChange={(e) => setIP(e.target.value)}
                value={ip}
              />{' '}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: '100%',
                  mt: 1
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">https://</InputAdornment>
                }}
                id="outlined-basic"
                label="API"
                variant="outlined"
                onChange={(e) => setAPI(e.target.value)}
                value={api}
              />{' '}
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={props.handleCloseEditUser}>
          Cancelar
        </Button>
        <Button autoFocus onClick={() => handleUpdateServer(name, ip, api)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
