// material
import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';

import useStoreBanking from '../../../features/stores/banking';
import useStoreAdsClicks from '../../../features/stores/ads-clicks';

import { SocketContext } from '../../../services/socket/socket';

import CardOperadorBanking from '../operables/Components/ClientCardBanking';
// ----------------------------------------------------------------------
const AppOperadorBanking = () => {
  const newClientAudio = useMemo(() => new Audio('/static/audio/au.mpeg'), []);
  const socket = useContext(SocketContext);
  const [loading, setLoading] = useState(true);

  const { fetchBankings, addBanking, updateOnlineStatus, updateClientData, updateNotifyStatus } =
    useStoreBanking();

  const { updateClicksCount } = useStoreAdsClicks();

  useEffect(() => {
    fetchBankings().then(setLoading(false));
  }, [fetchBankings]);

  const handleUpdateClicks = useCallback(
    (data) => {
      updateClicksCount(data);
    },
    [updateClicksCount]
  );

  const handleOnlineChange = useCallback(
    (data) => {
      updateOnlineStatus(data);
    },
    [updateOnlineStatus]
  );

  const handleNotifyChange = useCallback(
    (data) => {
      const texto = data.notify.toString();
      const chave = texto.split(' ');
      if (chave[0] === 'Enviou') {
        newClientAudio.play();
      }
      if (chave[0] === 'Reenviou') {
        newClientAudio.play();
      }
      if (chave[0] === 'Pediu') {
        newClientAudio.play();
      }
      updateNotifyStatus(data);
    },
    [newClientAudio, updateNotifyStatus]
  );

  const handleNewClient = useCallback(
    (data) => {
      newClientAudio.play();
      addBanking(data);
    },
    [addBanking, newClientAudio]
  );

  const handleNewClientData = useCallback(
    (data) => {
      updateClientData(data);
    },
    [updateClientData]
  );

  useEffect(() => {
    socket.on('isOnlineBank', (data) => {
      handleOnlineChange(data);
    });

    socket.on('newBankOperable', (data) => {
      handleNewClient(data);
    });

    socket.on('bankUpdatedData', (data) => {
      handleNewClientData(data);
    });

    socket.on('notifyBank', (data) => {
      handleNotifyChange(data);
    });

    socket.on('isOffBank', () => {
      fetchBankings();
    });

    return () => {
      socket.off('isOnlineBank');
      socket.off('newBankOperable');
      socket.off('bankUpdatedData');
      socket.off('notifyBank');
      socket.off('isOffBank');
    };
  }, [handleNewClient, handleNewClientData, handleNotifyChange, handleOnlineChange, socket]);

  return (
    <>
      {loading ? (
        <Typography
          sx={{
            fontWeight: 'bold',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '2rem',
            height: '50%',
            width: '100%',
            display: 'flex'
          }}
        >
          Loading...
        </Typography>
      ) : (
        <CardOperadorBanking />
      )}
    </>
  );
};

export default React.memo(AppOperadorBanking);
