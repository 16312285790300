import { useEffect, useState } from 'react';
import { GoVerified } from 'react-icons/go';
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { SiBitcoincash } from 'react-icons/si';
import { Grid, Container, Typography, Stack, Box, Card, Button, TextField } from '@mui/material';
// components
import useStore from '../features/stores/wallet-checker';
import Page from '../components/Page';
// sections
import { AppNewsUpdate, AppWidgetSummary } from '../components/_dashboard/app';
// ----------------------------------------------------------------------
import LoadingCoin from '../assets/img/loadingCoin.gif';

export default function WalletChecker() {
  const [phrases, setPhrases] = useState('');
  const {
    palavras,
    addPalavras,
    totalVerificadas,
    totalLisas,
    totalComSaldo,
    totalDolar,
    results,
    isLoading
  } = useStore();

  const handleInitCheck = (e) => {
    e.preventDefault();

    if (phrases.length > 0) {
      const valores = phrases.split('\n');

      addPalavras(valores);
      return;
    }
    alert('Nenhuma frase detectada');
  };

  useEffect(() => {}, [results]);

  return (
    <Page title="Checker - Web3">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Wallet Checker
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Verificadas"
              total={totalVerificadas}
              icon={<GoVerified size={25} />}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total com saldo"
              color="success"
              total={totalComSaldo}
              icon={<SiBitcoincash size={25} />}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Lisas"
              total={totalLisas}
              color="warning"
              icon={<MdMoneyOff size={25} />}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total em dolar"
              color="success"
              total={totalDolar}
              icon={<MdAttachMoney size={25} />}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card
              sx={{
                height: '100%'
              }}
            >
              <Stack>
                <Stack
                  sx={{
                    display: 'flex',
                    padding: 2
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    Frases {palavras.length}{' '}
                    <Button variant="outlined">
                      <Typography>CHECK BY DB</Typography>
                    </Button>
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    margin: 1,
                    display: 'flex',
                    height: 380
                  }}
                >
                  <TextField
                    sx={{
                      width: '100%',
                      height: 400,
                      overflowY: 'scroll'
                    }}
                    onChange={(e) => setPhrases(e.target.value)}
                    multiline
                    id="outlined-basic"
                  />
                </Box>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: 2
                  }}
                >
                  {/* <Button>
                    <Typography>Testare</Typography>
                  </Button> */}
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: '#0B831F',
                      color: '#0B831F',
                      '&:hover': { bgcolor: '#48B61F', color: 'white' }
                    }}
                    onClick={handleInitCheck}
                    disabled={isLoading}
                  >
                    <Typography>Iniciar</Typography>
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppNewsUpdate
              title="Verificadas"
              list={results.map((result, index) => ({
                id: index,
                title: result.address,
                description: result.phrase,
                balance: result.balance,
                lisa: result.lisa,
                image: LoadingCoin,
                date: result.verifyHour
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
