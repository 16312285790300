import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  dominios: [],
  usersOnDomain: [],
  allUsersAndDomains: [],
  fetchDominios: async () => {
    const response = await api.get('/api/pages', {
      headers: authHeader()
    });

    set({ dominios: response.data });
  },

  fetchallUsersAndDomains: async () => {
    const response = await api.get('/api/pages/all/domain', {
      headers: authHeader()
    });

    set({ allUsersAndDomains: response.data });
  },

  fetchUsersByDomainId: async (id) => {
    const response = await api.get(`/api/pages/users/domain/${id}`, {
      headers: authHeader()
    });
    set({ usersOnDomain: response.data });
  },
  setDomainAndUser: async (userId, domainId) => {
    const response = await api.put(
      `/api/pages/usersbydomain/${userId}/domain/${domainId}/`,
      {},
      {
        headers: authHeader()
      }
    );
    set({
      usersOnDomain: [...new Set([...get().usersOnDomain, response.data])]
    });
    set({
      allUsersAndDomains: [...new Set([...get().allUsersAndDomains, response.data])]
    });
  },

  deleteLinkedDomain: async (userId, domainId) => {
    await api.delete(`/api/pages/usersbydomain/${userId}/domain/${domainId}`, {
      headers: authHeader()
    });
    set({
      usersOnDomain: get().usersOnDomain.filter((wallet) => wallet.userId !== userId)
    });
    set({
      allUsersAndDomains: get().allUsersAndDomains.filter((wallet) => wallet.userId !== userId)
    });
  },

  deleteDominio: async (id) => {
    await api.delete(`/api/pages/${id}`, { headers: authHeader() });
    set({
      dominios: get().dominios.filter((dominio) => dominio.id !== id)
    });
  },
  addDominio: async (name, path) => {
    const response = await api.post('/api/pages', { name, path }, { headers: authHeader() });

    set({ dominios: [...new Set([...get().dominios, response.data])] });
  },

  debugSSLDominio: async (path) => {
    await api.post('/api/pages/debug', { path }, { headers: authHeader() });
  },

  ativarSsl: async (id, url) => {
    const response = await api.patch(`/api/pages/${id}`, { url }, { headers: authHeader() });

    set((state) => ({
      dominios: state.dominios.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            url: response.data.url
          };
        }
        return item;
      })
    }));
  },

  onOffWallet: async (id, status) => {
    const response = await api.patch(
      `/api/pages/onoff/${id}`,
      { status },
      { headers: authHeader() }
    );

    set((state) => ({
      dominios: state.dominios.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },

  onOffExtensionRedir: async (id, status) => {
    const response = await api.patch(
      `/api/pages/onoff-redir/${id}`,
      { extensao_redir: status },
      { headers: authHeader() }
    );

    set((state) => ({
      dominios: state.dominios.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            extensao_redir: response.data.extensao_redir
          };
        }
        return item;
      })
    }));
  }
});
const useStore = create(devtools(store));

export default useStore;
