import { Grid, Container, Typography, Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { BsDownload } from 'react-icons/bs';
import { FaWolfPackBattalion } from 'react-icons/fa';
// components
import Page from '../components/Page';
// sections
import { IpsBlocked } from '../components/_dashboard/app';

import ipBlockedClicks from '../features/stores/ip-blocked';
// ----------------------------------------------------------------------

export default function IpBlockedList() {
  const { fetchBlockedIps, ipsBlockeds, generateIpsBlockTxt, deleteAll, deleteMenor5 } =
    ipBlockedClicks();

  const updateList = async () => {
    await deleteMenor5();
    await fetchBlockedIps();
  };

  useEffect(() => {
    fetchBlockedIps();
  }, []);

  return (
    <Page title="Attack View">
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            Attack View
          </Typography>

          <Box
            sx={{
              display: 'flex',
              width: 500,
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              textAlign: 'right'
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: '#DD4913',
                color: '#DD4913',
                '&:hover': { borderColor: '#DD4913', bgcolor: '#DD4913', color: 'white' }
              }}
              onClick={() => generateIpsBlockTxt('ip')}
            >
              Baixar IPs &nbsp;
              <BsDownload />
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: '#27C368',
                color: '#27C368',
                '&:hover': { borderColor: '#27C368', bgcolor: '#27C368', color: 'white' }
              }}
              onClick={deleteAll}
            >
              Excluir todos 🗑&nbsp;
            </Button>

            <Button
              variant="outlined"
              sx={{
                borderColor: '#E91E61',
                color: '#E91E61',
                '&:hover': { borderColor: '#E91E61', bgcolor: '#E91E61', color: 'white' }
              }}
              onClick={updateList}
            >
              Manter atacantes &nbsp;
              <FaWolfPackBattalion size={12} />
            </Button>
          </Box>
        </Box>
        <Grid item xs={12} md={12} lg={12}>
          <IpsBlocked
            title="Todos os Acessos"
            list={ipsBlockeds
              .sort((a, b) => (a.id > b.id ? -1 : 1))
              .map((result) => ({
                id: result.id,
                ip: result.ip_addr,
                referer: result.referer,
                dns: result.dns,
                clicks: result.clicks,
                isBlock: result.is_block,
                userAgent: result.user_agent,
                updatedAt: result.updatedAt
              }))}
          />
        </Grid>
      </Container>
    </Page>
  );
}
