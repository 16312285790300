import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  automatics: [],
  fetchAutomatics: async () => {
    const response = await api.get('/api/automatics', {
      headers: authHeader()
    });
    set({ automatics: response.data });
  },
  deleteAutomatic: async (id) => {
    await api.delete(`/api/automatics/${id}`, { headers: authHeader() });
    set({
      automatics: get().automatics.filter((automatic) => automatic.id !== id)
    });
  },
  addAutomatic: (automatic) => {
    set({ automatics: [...new Set([...get().automatics, automatic])] });
  }
});

const useStore = create(devtools(store));

export default useStore;
