import { filter } from 'lodash';
import { useEffect, useState, useContext } from 'react';
import {
  AiOutlineDownload,
  AiOutlineFieldTime,
  AiFillDelete,
  AiOutlinePlusCircle
} from 'react-icons/ai';
import { BsJournalBookmark, BsRadioactive } from 'react-icons/bs';
import { RxUpdate } from 'react-icons/rx';
// material
import {
  Card,
  Table,
  Stack,
  Grid,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Link,
  Box
} from '@mui/material';
// components

import { SocketContext } from '../services/socket/socket';

import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ExtensionListToolbar,
  ExtensionListHead,
  ExtensionMoreMenu
} from '../components/_dashboard/extension/components';

import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales
} from '../components/_dashboard/app';

import AddExtensionLinkDialog from '../layouts/dashboard/AddExtensionLink';

//
import ExtensionLogsDialog from '../components/_dashboard/extension/components/ExtensionLogsDialog';
import useExtensionStore from '../features/stores/extension';
import useUserStore from '../features/stores/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'cliente', label: 'IP do Cliente', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'responsavel', label: 'Responsável', alignRight: false },
  { id: 'id', label: 'ID extensão', alignRight: false },
  { id: 'pag', label: 'Link de instalação', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Extension() {
  const socket = useContext(SocketContext);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { fetchExtensions, extensions, unninstall } = useExtensionStore();
  const { userData } = useUserStore();

  useEffect(() => {
    fetchExtensions();
  }, [fetchExtensions]);

  useEffect(() => {
    socket.on('removeInstalation', () => {
      fetchExtensions();
    });

    return () => {
      socket.off('removeInstalation');
    };
  }, [fetchExtensions, socket]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = extensions.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - extensions.length) : 0;

  const filteredUsers = applySortFilter(extensions, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [openExtensionLinkDialog, setOpenExtensionLinkDialog] = useState(false);

  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenExtensionLinkDialog = (e) => {
    e.preventDefault();
    setOpenExtensionLinkDialog(true);
  };

  const handleCloseExtensionLinkDialog = () => {
    setOpenExtensionLinkDialog(false);
  };

  return (
    <Page title="Painel | Extensão">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Gerenciamento da Extensão 🧩
          </Typography>

          {userData[0].role === 'ADMIN' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 350
              }}
            >
              <Button
                sx={{ color: 'white' }}
                variant="contained"
                onClick={(e) => handleClickOpenExtensionLinkDialog(e)}
              >
                <AiOutlinePlusCircle />
                &nbsp; Novo Link
              </Button>
              <Button
                sx={{ color: 'white', bgcolor: 'red' }}
                variant="contained"
                onClick={handleClickOpen}
              >
                <BsRadioactive />
                &nbsp;Blacklist
              </Button>
              <Button sx={{ color: 'white' }} variant="contained" onClick={handleClickOpen}>
                <BsJournalBookmark />
                &nbsp;Logs
              </Button>
            </Box>
          ) : (
            ''
          )}
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              title="Instaladas"
              icon={<AiOutlineDownload />}
              value={extensions.length}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers title="Atualizadas" icon={<RxUpdate />} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders title="Aguardando Atualização" icon={<AiOutlineFieldTime />} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports
              title="Desinstalações"
              icon={<AiFillDelete />}
              value={unninstall.length}
            />
          </Grid>
        </Grid>

        <Card sx={{ mt: 4 }}>
          <ExtensionListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ExtensionListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={extensions.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <ExtensionLogsDialog isOpen={open} handleClose={handleClose} />

                <AddExtensionLinkDialog
                  isOpen={openExtensionLinkDialog}
                  handleClose={handleCloseExtensionLinkDialog}
                />

                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textTransform: 'uppercase'
                                }}
                              >
                                {row.ip_addr}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textTransform: 'uppercase'
                                }}
                              >
                                {row.status}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              textTransform: 'uppercase'
                            }}
                          >
                            {row.responsavel}
                          </TableCell>
                          <TableCell align="left">{row.clientId}</TableCell>
                          <TableCell align="left">
                            <Link
                              style={{
                                pointerEvents: row.url === 'Sem Link' ? 'none' : ''
                              }}
                              href={row.urlInstalacao}
                              underline="none"
                              target="_blank"
                            >
                              VER LINK
                            </Link>
                          </TableCell>

                          <TableCell align="right">
                            <ExtensionMoreMenu id={row.id} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={extensions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
