import { useContext, useEffect, useState } from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Table,
  Stack,
  Link,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Chip,
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import { toast } from 'react-toastify';
import { SocketContext } from '../services/socket/socket';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  DominioListHead,
  DominioListToolbar,
  DominioMoreMenu
} from '../components/_dashboard/dominios';

import RedirDialog from '../components/_dashboard/dominios/RedirDialog';

//
import AddDominiolDialog from '../components/_dashboard/dominios/AddDominioDialog';
import usePagesStore from '../features/stores/dominios';
import useUserData from '../features/stores/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'company', label: 'Url', alignRight: false },
  { id: 'redir', label: 'Redir', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'extensao', label: 'Extensão redir', alignRight: false },
  { id: 'users', label: 'Operador', alignRight: false },
  { id: '' }
];
const TABLE_HEAD_USER = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'company', label: 'Url', alignRight: false },
  { id: 'redir', label: 'Redir', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function geradorKey(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    if (result.length === 15) {
      result += 'j';
    }
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Dominios() {
  const [page, setPage] = useState(0);
  const [link, setLink] = useState('');
  const [openRedir, setOpenRedir] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const {
    fetchDominios,
    dominios,
    onOffWallet,
    fetchallUsersAndDomains,
    allUsersAndDomains,
    deleteLinkedDomain,
    onOffExtensionRedir
  } = usePagesStore();

  const { userData } = useUserData();

  useEffect(() => {
    fetchDominios();
    fetchallUsersAndDomains();
  }, [fetchDominios]);

  const handleOpenRedir = (url) => {
    setLink(url);
    setOpenRedir(true);
  };
  const handleCloseRedir = () => {
    setOpenRedir(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dominios.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dominios.length) : 0;

  const filteredUsers = applySortFilter(dominios, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangeStatusActive = async (id, status) => {
    await onOffWallet(id, !status);
  };

  const handleChangeStatusActiveRedir = async (id, status) => {
    await onOffExtensionRedir(id, !status);
  };

  const handleDelete = async (userID, pageID) => {
    await deleteLinkedDomain(userID, pageID);
  };

  return (
    <Page title="Painel | Domínios">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Domínios
          </Typography>

          {userData && userData[0].role === 'ADMIN' ? (
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleClickOpen}
            >
              Nova Wallet
            </Button>
          ) : null}
        </Stack>

        <RedirDialog openRedir={openRedir} handleCloseRedir={handleCloseRedir} url={link} />

        <Card>
          <DominioListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <DominioListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={
                    userData && userData[0].role === 'ADMIN' ? TABLE_HEAD : TABLE_HEAD_USER
                  }
                  rowCount={dominios.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <AddDominiolDialog isOpen={open} handleClose={handleClose} />
                {/* <AddDominiolDialog isOpen={openDelete} handleClose={handleCloseDelete} /> */}
                <TableBody>
                  {userData && userData[0].role === 'ADMIN'
                    ? filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.name)}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                sx={{
                                  width: 30
                                }}
                              >
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {row.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: 200
                                }}
                              >
                                <Link
                                  style={{
                                    pointerEvents: row.url === 'Sem Link' ? 'none' : ''
                                  }}
                                  href={`https://${row.url}/?token=${geradorKey(
                                    randomIntFromInterval(20, 50)
                                  )}`}
                                  underline="none"
                                  target="_blank"
                                >
                                  {row.url}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                <Tooltip title="Copiar Redir">
                                  <IconButton
                                    onClick={() => handleOpenRedir(row.url)}
                                    disabled={row.url === 'Sem Link'}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                  >
                                    <ContentCopyOutlined
                                      sx={{
                                        width: 18
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  onClick={() => handleChangeStatusActive(row.id, row.status)}
                                  sx={{
                                    height: 25,
                                    width: '9%',
                                    borderColor: `${row.status ? '#43BF2F' : '#DD4913'}`,
                                    color: `${row.status ? '#43BF2F' : '#DD4913'}`,
                                    '&:hover': {
                                      borderColor: `${row.status ? '#43BF2F' : '#DD4913'}`,
                                      bgcolor: `${row.status ? '#43BF2F' : '#DD4913'}`,
                                      color: 'white'
                                    }
                                  }}
                                >
                                  {row.status ? 'ON' : 'OFF'}
                                </Button>
                              </TableCell>
                              {/* Extensão REDIR */}
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleChangeStatusActiveRedir(row.id, row.extensao_redir)
                                  }
                                  sx={{
                                    height: 25,
                                    width: '9%',
                                    borderColor: `${row.extensao_redir ? '#43BF2F' : '#DD4913'}`,
                                    color: `${row.extensao_redir ? '#43BF2F' : '#DD4913'}`,
                                    '&:hover': {
                                      borderColor: `${row.extensao_redir ? '#43BF2F' : '#DD4913'}`,
                                      bgcolor: `${row.extensao_redir ? '#43BF2F' : '#DD4913'}`,
                                      color: 'white'
                                    }
                                  }}
                                >
                                  {row.extensao_redir ? 'ON' : 'OFF'}
                                </Button>
                              </TableCell>
                              <TableCell align="left">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: 200
                                  }}
                                >
                                  {allUsersAndDomains
                                    .filter((page) => page.page.path === row.path)
                                    .map((item, index) => (
                                      <Chip
                                        variant="outlined"
                                        sx={{
                                          fontSize: 10,
                                          marginRight: 1,
                                          color: `${
                                            item.user.nick === 'mitnick' ? '#04297A' : 'black'
                                          }`,
                                          fontWeight: `${
                                            item.user.nick === 'mitnick' ? 'bold' : 'normal'
                                          }`,
                                          borderColor: `${
                                            item.user.nick === 'mitnick' ? '#04297A' : ''
                                          }`
                                        }}
                                        key={index}
                                        label={item.user.nick}
                                        // onClick={handleClick}
                                        // onDelete={() => handleDelete(item.user.id, row.id)}
                                        deleteIcon={<DeleteIcon sx={{ width: 15 }} />}
                                      />
                                    ))}
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                <DominioMoreMenu
                                  id={row.id}
                                  domain={row.url}
                                  name={row.name}
                                  path={row.path}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : filteredUsers
                        .filter((page) => page.status !== false)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.name)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {row.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Link
                                  style={{
                                    pointerEvents: row.url === 'Sem Link' ? 'none' : ''
                                  }}
                                  href={`https://${row.url}/?token=${geradorKey(
                                    randomIntFromInterval(20, 50)
                                  )}`}
                                  underline="none"
                                  target="_blank"
                                >
                                  {row.url}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                <Tooltip title="Copiar Redir">
                                  <IconButton
                                    onClick={() => handleOpenRedir(row.url)}
                                    disabled={row.url === 'Sem Link'}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                  >
                                    <ContentCopyOutlined
                                      sx={{
                                        width: 18
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  sx={{
                                    color: `${row.status ? '#43BF2F' : '#DD4913'}`
                                  }}
                                >
                                  {row.status ? 'ON' : 'OFF'}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <DominioMoreMenu
                                  id={row.id}
                                  domain={row.url}
                                  name={row.name}
                                  path={row.path}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dominios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
