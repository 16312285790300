import jwtDecode from 'jwt-decode';
import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LoadingButton } from '@mui/lab';
// Stores
import useAuthDataStore from '../../../features/stores/auth';
import useUserDataStore from '../../../features/stores/user';
import useSettingsStore from '../../../features/stores/settings';
import LoginDialog from './LoginDialog';
// ----------------------------------------------------------------------

const useStyles = makeStyles({
  input: {
    color: 'white'
  }
});

export default function LoginForm() {
  const { signin, authData } = useAuthDataStore();
  const { addUserData } = useUserDataStore();
  const { fetchSettings } = useSettingsStore();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [passwd, setPasswd] = useState();
  const classes = useStyles();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Nick is required'),
    password: Yup.string().required('Password is required')
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleLogin = async () => {
    const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
    const permissionStatus = await navigator.permissions.query(queryOpts);

    if (permissionStatus.state === 'denied' || permissionStatus.state === 'prompt') {
      setOpen(true);
      const text = await navigator.clipboard.readText();
      if (permissionStatus.state === 'granted') {
        await signin(email, passwd);
        const local = JSON.parse(sessionStorage.getItem('userData'));
        const data = jwtDecode(local.access_token);
        addUserData(data);
        fetchSettings();

        if (authData) {
          navigate('/dashboard/app', { replace: true });
          window.location.reload();
        }
      }
    }

    if (permissionStatus.state === 'granted') {
      await signin(email, passwd);
      const local = JSON.parse(sessionStorage.getItem('userData'));
      const data = jwtDecode(local.access_token);
      addUserData(data);
      fetchSettings();

      if (authData) {
        navigate('/dashboard/app', { replace: true });
        window.location.reload();
      }
    }
  };

  return (
    <FormikProvider value={formik}>
      <LoginDialog isOpen={open} handleClose={handleClose} />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            inputProps={{ className: classes.input }}
            fullWidth
            type="text"
            label="Nick"
            helperText={touched.nick && errors.email}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <TextField
            sx={{ borderColor: '#A9DF6A' }}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onChange={(e) => setPasswd(e.target.value)}
            value={passwd}
            color="primary"
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={handleLogin}
          sx={{
            bgcolor: '#67C152'
          }}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
