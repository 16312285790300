import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlineLogout } from 'react-icons/ai';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// ----------------------------------------------------------------------
import AppDeleteUserDialog from '../app/AppDeleteUserDialog';
import EditUserDialog from '../../../layouts/dashboard/EditUserDialog';
import LogoutUserDialog from '../../../layouts/dashboard/LogoutUserDialog';

export default function UserMoreMenu({ id, nick }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [openEditUser, setOpenEditUser] = useState(false);
  const [openLogoutUser, setOpenLogoutUser] = useState(false);

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const handleCloseLogoutUser = () => {
    setOpenLogoutUser(false);
  };

  const handleOpenLogoutUser = () => {
    setOpenLogoutUser(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <AppDeleteUserDialog id={id} nick isOpenDialog={open} handleClose={handleClose} />
      <EditUserDialog
        id={id}
        nick={nick}
        isOpenEditUser={openEditUser}
        handleCloseEditUser={handleCloseEditUser}
      />

      <LogoutUserDialog
        id={id}
        isOpenLogoutUser={openLogoutUser}
        handleCloseLogoutUser={handleCloseLogoutUser}
      />

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
          <ListItemIcon>
            <RiDeleteBinLine width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpenEditUser}>
          <ListItemIcon>
            <BiEditAlt width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpenLogoutUser}>
          <ListItemIcon>
            <AiOutlineLogout color="red" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            sx={{ color: 'red' }}
            primary="Deslogar"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
