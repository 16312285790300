import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import useConfigsStore from '../../features/stores/settings';

export default function TimerOnScreenModal({ id, isOpen, handleClose }) {
  const [seconds, setSeconds] = React.useState('');
  const { saveTimerToBlockBot } = useConfigsStore();

  const handleSaveTimerToBlock = async (segundos) => {
    await saveTimerToBlockBot(segundos);
    handleClose();
    setSeconds('');
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Quantidade de segundos para bot detect</DialogTitle>
        <DialogContent>
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                sx={{
                  width: '100%',
                  mt: 1
                }}
                id="outlined-basic"
                label="Ex: 5"
                variant="outlined"
                onChange={(e) => setSeconds(e.target.value)}
                value={seconds}
                type="number"
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button autoFocus onClick={() => handleSaveTimerToBlock(seconds)}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
