import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import { useEffect } from 'react';
import useUserStore from '../../features/stores/user';

export default function EditUserDialog(props) {
  const { addNewUser, editUser } = useUserStore();

  const [nick, setNick] = React.useState('');
  const [pass, setPass] = React.useState('');

  const handleSaveUser = async (nick, pass) => {
    if (nick && pass) {
      await editUser(props.id, nick, pass);
      setPass('');
      setNick('');
      props.handleCloseEditUser();
    } else {
      alert('Preencha todos os campos');
    }
  };

  useEffect(() => {
    setNick(props.nick);
  }, []);

  return (
    <Dialog
      open={props.isOpenEditUser}
      onClose={props.handleCloseEditUser}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Editar usuário</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: 150,
                  mt: 1
                }}
                id="outlined-basic"
                label="Nick"
                variant="outlined"
                onChange={(e) => setNick(e.target.value)}
                value={nick}
              />
              &nbsp;
              <TextField
                sx={{
                  width: 400,
                  mt: 1
                }}
                id="outlined-basic"
                label="Senha"
                variant="outlined"
                onChange={(e) => setPass(e.target.value)}
                value={pass}
              />{' '}
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={props.handleCloseEditUser}>
          Cancelar
        </Button>
        <Button autoFocus onClick={() => handleSaveUser(nick, pass)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
