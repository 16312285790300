import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { AiOutlineCloudDownload } from 'react-icons/ai';
import { BsEraser } from 'react-icons/bs';
import { GrSend } from 'react-icons/gr';
import { useEffect } from 'react';
import useServerStore from '../../features/stores/server';

export default function ServerProxyBotDialog(props) {
  const { deleteMyLogsLogs, myProxyes, generateTxt, deleteLogs, handleUploadProxyBot } =
    useServerStore();

  const [file, setFile] = React.useState(null);
  const [buttonText, setButtonText] = React.useState('Escolher arquivo');
  const [isLoading, setLoading] = React.useState(false);

  const handleClearLogs = () => {
    deleteMyLogsLogs();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.name.endsWith('.txt')) {
      setFile(selectedFile);
      setButtonText('Enviar');
    } else {
      setFile(null);
      setButtonText('Escolher arquivo');
      alert('Selecione um arquivo .txt');
    }
  };

  const handleUpload = async () => {
    await handleUploadProxyBot(file);
  };

  const handleDownloadLogs = () => {
    generateTxt(props.name, 'myserver');
  };

  return (
    <Dialog
      open={props.isopenProxyBot}
      onClose={props.handleClickCloseProxyPesquisa}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Proxys do servidor ➡️ [{props.name}]</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box
            sx={{
              height: 500,
              width: 600
            }}
          >
            {myProxyes.map((log, index) => (
              <Typography
                sx={{
                  paddingRight: 3,
                  fontSize: 12
                }}
                key={index}
              >
                {log.text.trim()}
              </Typography>
            ))}
          </Box>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ marginRight: 4 }}
          variant="outlined"
          color="error"
          onClick={props.handleClickCloseProxyPesquisa}
        >
          Cancelar
        </Button>
        <form noValidate autoComplete="off">
          <input
            id="file"
            type="file"
            accept=".txt"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />

          {!file && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => document.getElementById('file').click()}
            >
              {buttonText}
            </Button>
          )}

          {isLoading && <p>Enviando arquivo...</p>}
          {file && (
            <Button variant="contained" color="primary" onClick={handleUpload}>
              <GrSend /> &nbsp;Enviar
            </Button>
          )}
        </form>
      </DialogActions>
    </Dialog>
  );
}
