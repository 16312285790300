import api from '../api/api';

const local = JSON.parse(localStorage.getItem('userData'));

export const signin = (nick, password) => api.post('/api/auth/local/signin', { nick, password });

export const getAllAutomatics = () =>
  api.get('/api/automatics', {
    // headers: {
    //   authorization: local.token
    // }
  });

export const setWhitdrawed = (id) =>
  api.put(
    `/house/whitdraw`,
    { id },
    {
      headers: {
        authorization: local.token
      }
    }
  );

export const setBroke = (id) =>
  api.put(
    '/house/broke',
    { id },
    {
      headers: {
        authorization: local.token
      }
    }
  );

export const setVerified = (id) =>
  api.put(
    '/house/verify',
    { id },
    {
      headers: {
        authorization: local.token
      }
    }
  );

export const setDelete = (id) =>
  api.put(
    '/house/delete',
    { id },
    {
      headers: {
        authorization: local.token
      }
    }
  );
