import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack, Box, List, Divider, ListItem, Typography } from '@mui/material';

import { AiFillEdit } from 'react-icons/ai';

import useExtensionStore from '../../features/stores/extension';

export default function AddExtensionLinkDialog(props) {
  const [edtText, setEdtText] = React.useState('Adicionar');
  const [nick, setNick] = React.useState('');
  const [link, setLink] = React.useState('');
  const [updatedON, setUpdatedON] = React.useState('');

  const { addOwner, owners, fetchOwners, editOwner } = useExtensionStore();

  // Adicionar dono da extensão
  const handleAddExtensionOwner = async () => {
    if (updatedON && nick && link) {
      await editOwner(nick, link);
      setUpdatedON(false);
      props.handleClose();
      return;
    }

    if (nick && link) {
      await addOwner(nick, link);
      setNick('');
      setLink('');
      props.handleClose();
    } else {
      alert('Preencha todos os campos!');
    }
  };

  const handleEditExtension = (e, nick) => {
    e.preventDefault();
    setNick(nick);
    setUpdatedON(true);
    setLink('');
    setEdtText('Editando');
  };

  React.useEffect(() => {
    fetchOwners();
  }, []);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">🔗 {edtText} Link da extensão 🧩</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: 150,
                  mt: 1
                }}
                id="outlined-basic"
                label="Nick"
                variant="outlined"
                onChange={(e) => {
                  setUpdatedON(false);
                  setEdtText('Adicionar');
                  setNick(e.target.value.trim().toLocaleLowerCase());
                }}
                value={nick}
              />
              &nbsp;
              <TextField
                sx={{
                  width: 400,
                  mt: 1
                }}
                id="outlined-basic"
                label="Url da extensão"
                variant="outlined"
                onChange={(e) => setLink(e.target.value.trim())}
                value={link}
              />{' '}
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="error" onClick={props.handleClose}>
          Cancelar
        </Button>
        <Button autoFocus onClick={handleAddExtensionOwner}>
          Salvar
        </Button>
      </DialogActions>

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            {owners &&
              owners.map((owner, i) => (
                <ListItem key={i}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 10,
                        width: 60,
                        fontWeight: 'bold'
                      }}
                    >
                      {owner.nick}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 8
                      }}
                    >
                      {owner.url}
                    </Typography>
                    <Button variant="text" onClick={(e) => handleEditExtension(e, owner.nick)}>
                      <AiFillEdit size={20} />
                    </Button>
                  </Box>
                </ListItem>
              ))}
          </List>
        </nav>
      </Box>
    </Dialog>
  );
}
