import { useEffect, useState, useContext, useCallback } from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { IoMdArrowRoundBack } from 'react-icons/io';
// material
import {
  Card,
  Table,
  Stack,
  Box,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Link
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { AtkListHead, AtkListToolbar, AtkMoreMenu } from '../components/_dashboard/bot/atk';

//
import AddAdsDialog from '../components/_dashboard/bot/components/AddAdsDialog';
import useBotStore from '../features/stores/bot';
import useUserStore from '../features/stores/user';
import useServerStore from '../features/stores/server';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'url', label: 'Url da Campanha', alignRight: false },
  { id: 'criador', label: 'Criador', alignRight: false },
  { id: 'click', label: 'Click', alignRight: false },
  { id: 'servidor', label: 'Servidor', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'acao', label: 'Ação', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.url.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BotAttack() {
  const SlurpAudio = new Audio('/static/audio/slurp.mp3');
  const [serverForAttack, setServerForAttack] = useState('');
  const [urlForAttack, setURLForAttack] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { fetchBotServers, serversBot } = useServerStore();
  const { fetchAttack, attack, setAttack, addAttack } = useBotStore();
  const { userData } = useUserStore();

  useEffect(() => {
    fetchBotServers();
    fetchAttack();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = attack.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - attack.length) : 0;

  const filteredUsers = applySortFilter(attack, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeServer = (event) => {
    setServerForAttack(event.target.value);
  };

  const handleSetAttack = (event, id, status) => {
    event.preventDefault();
    SlurpAudio.play();
    setAttack(id, status);
  };

  const handleAttack = (e) => {
    e.preventDefault();
    if (urlForAttack !== '') {
      addAttack(urlForAttack, serverForAttack, 1, userData[0].nick);
      setURLForAttack('');
    }
  };

  const handleOpenLink = (href) => {
    window.open(href, '_blank');
  };

  return (
    <Page title="Painel | ATTACK">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            [BOT - Attack ADS]
          </Typography>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Button
              component={RouterLink}
              to="/dashboard/bot"
              variant="contained"
              onClick={handleClickOpen}
            >
              <IoMdArrowRoundBack /> &nbsp; Voltar
            </Button>
          </Box>
        </Stack>

        <Card
          sx={{
            display: 'flex',
            padding: 1,
            justifyContent: 'space-between'
          }}
        >
          <TextField
            id="standard-basic"
            label="URL"
            variant="outlined"
            sx={{ width: '50%' }}
            value={urlForAttack}
            onChange={(e) => setURLForAttack(e.target.value)}
          />
          <FormControl fullWidth sx={{ width: '30%' }}>
            <InputLabel id="demo-simple-select-label">Servidor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={serverForAttack}
              label="Servidor"
              onChange={handleChangeServer}
            >
              {serversBot.map((server, index) => (
                <MenuItem key={index} value={server.id}>
                  Server #{server.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            onClick={handleAttack}
            variant="contained"
            sx={{ backgroundColor: 'red', color: 'white' }}
          >
            Iniciar Attack
          </Button>
        </Card>

        <Card
          sx={{
            marginTop: 5
          }}
        >
          <AtkListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <AtkListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={attack.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = selected.indexOf(row.name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.name)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none" align="left">
                            <Stack direction="row" alignItems="center">
                              <Link
                                onClick={(e) => handleOpenLink(row.url)}
                                variant="subtitle2"
                                noWrap
                                href=""
                                sx={{ cursor: 'pointer', width: 400 }}
                                target="_blank"
                              >
                                {row.url}
                              </Link>
                            </Stack>
                          </TableCell>
                          <TableCell align="left" component="th" scope="row" padding="none">
                            {row.nick}
                          </TableCell>
                          <TableCell align="left">{row.click}</TableCell>
                          <TableCell align="left" component="th" scope="row" padding="none">
                            Server #{row.server}
                          </TableCell>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{
                              fontWeight: 'bold',
                              color: `${row.status === 0 ? 'red' : 'green'}`,
                              width: 80
                            }}
                          >
                            {row.status === 0 ? 'Attack Off' : 'Attack On'}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Button
                              onClick={(e) => handleSetAttack(e, row.id, row.status === 0 ? 1 : 0)}
                              variant="contained"
                              sx={{
                                fontSize: 12,
                                backgroundColor: `${row.status === 0 ? 'green' : 'yellow'}`,
                                color: `${row.status === 0 ? 'white' : 'black'}`
                              }}
                            >
                              {row.status === 0 ? 'Start Attack' : 'Stop Attack'}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <AtkMoreMenu id={row.id} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={attack.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
