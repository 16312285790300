import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  extensions: [],
  unninstall: [],
  logs: [],
  owners: [],
  fetchExtensions: async () => {
    const response = await api.get('/api/extension/dados', {
      headers: authHeader()
    });

    set({ extensions: response.data.allClients });
    set({ unninstall: response.data.uninstaledExtensions });
  },

  fetchOwners: async () => {
    const response = await api.get('/api/extension/owners', {
      headers: authHeader()
    });
    set({ owners: response.data });
  },

  editOwner: async (nick, url) => {
    const response = await api.put(
      '/api/extension/owner/',
      { nick, url },
      { headers: authHeader() }
    );

    set((state) => ({
      owners: state.owners.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            url: response.data.url
          };
        }
        return item;
      })
    }));
  },

  addOwner: async (nick, url) => {
    const response = await api.post(
      '/api/extension/owner',
      { nick, url },
      { headers: authHeader() }
    );

    if (response.data === 'Extensão já cadastrada para o usuário') {
      alert(response.data);
    }

    return response.data;

    // set({ dominios: [...new Set([...get().dominios, response.data])] });
  },

  fetchLogs: async () => {
    const response = await api.get('/api/extension/logs', {
      headers: authHeader()
    });
    const data = response.data.split('\n').map((x) => ({ text: x }));
    set({ logs: data });
  }
});
const useStore = create(devtools(store));

export default useStore;
