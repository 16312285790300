import { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Box, Typography, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { CreateUserStatusColor, CreateUserStatusTextColor } from '../../utils/ui/createColors';
import useUsers from '../../features/stores/user';
import useExtension from '../../features/stores/extension';

import { SocketContext } from '../../services/socket/socket';
// ----------------------------------------------------------------------

export default function UserStatus() {
  const socket = useContext(SocketContext);

  const { users, fetchUsers, updateUser, userData } = useUsers();

  const notify = (args) => {
    toast(`${args} está ON ✅`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const handleNewAccessStatus = useCallback(
    (data) => {
      updateUser(data);
      notify(data.nick);
    },
    [updateUser]
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    socket.on('newLogin', (data) => {
      handleNewAccessStatus(data);
    });

    socket.on('isOffUser', () => {
      fetchUsers();
    });

    return () => {
      socket.off('newLogin');
      socket.off('isOffUser');
    };
  }, [handleNewAccessStatus, socket]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: 150,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 80,
        borderRadius: 0.5
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: 15
        }}
      >
        Usuários Online
      </Typography>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0.3,
          width: '100%'
        }}
      >
        {users &&
          users
            .filter((u) => u.status !== 'Offline' && u.nick !== userData[0].nick)
            .slice(-4)
            .sort((a, b) => (a.status < b.status ? -1 : -1))
            .map((ads) => (
              <Grid
                key={ads.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '30%',
                  justifyContent: 'space-between',
                  width: '50%',
                  borderRadius: 0.5,
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '90%',
                    height: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: `${CreateUserStatusColor(ads.status)}`,
                    borderRadius: 0.5,
                    marginLeft: 0.5,
                    mt: 0.5
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: `${CreateUserStatusTextColor(ads.status)}`,
                      alignItems: 'center',
                      p: 1
                    }}
                  >
                    &nbsp;{ads.nick}
                  </Typography>
                </Box>
              </Grid>
            ))}
      </Grid>
    </Box>
  );
}
