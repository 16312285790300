import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

let store = (set, get) => ({
  walletsUser: [],
  walletsUserbyUser: [],
  walletsUserbyUserWallet: [],
  settings: [],
  op: {},

  fetchSettings: async () => {
    const response = await api.get('/api/settings/wallets', {
      headers: authHeader()
    });
    set({ settings: response.data });
  },

  fetchSettingsWalletUser: async () => {
    const response = await api.get('/api/settings/walletssandusers', {
      headers: authHeader()
    });
    set({ walletsUser: response.data });
  },

  findAllWalletsAndUsersByUserID: async (id) => {
    const response = await api.get(`/api/settings/walletssandusersbyuser/${id}`, {
      headers: authHeader()
    });
    set({ walletsUserbyUser: response.data });
  },

  fetchAllWalletsAndUsersByWalletID: async (id) => {
    const response = await api.get(`/api/settings/usersbywallet/${id}`, {
      headers: authHeader()
    });
    set({ walletsUserbyUserWallet: response.data });
  },

  setWalletsAndUser: async (id, userId) => {
    const response = await api.put(
      `/api/settings/usersbywallet/${id}/user/${userId}/`,
      {},
      {
        headers: authHeader()
      }
    );
    set({
      walletsUserbyUserWallet: [...new Set([...get().walletsUserbyUserWallet, response.data])]
    });
    set({
      walletsUser: [...new Set([...get().walletsUser, response.data])]
    });
  },

  deleteLinkedWallet: async (userId, walletId) => {
    await api.delete(`/api/settings/usersbywallet/${userId}/wallet/${walletId}`, {
      headers: authHeader()
    });
    set({
      walletsUserbyUserWallet: get().walletsUserbyUserWallet.filter(
        (wallet) => wallet.userId !== userId
      )
    });

    set({
      walletsUser: get().walletsUser.filter((wallet) => wallet.userId !== userId)
    });
  },

  fetchConfig: async () => {
    const response = await api.get('/api/settings', {
      headers: authHeader()
    });
    set({ op: response.data });
  },

  enableApiProtect: async (id, status) => {
    const response = await api.put(
      '/api/settings/enable-protect',
      {
        id,
        apiProtect: status
      },
      {
        headers: authHeader()
      }
    );
    set({ op: response.data });
  },

  enableCloudProtect: async (id, status) => {
    const response = await api.put(
      '/api/settings/enable-cloud-protect',
      {
        id,
        cloudProtect: status
      },
      {
        headers: authHeader()
      }
    );
    set({ op: response.data });
    return response.data;
  },

  deleteSetting: async (id) => {
    await api.delete(`/api/settings/wallets/${id}`, { headers: authHeader() });
    set({
      settings: get().settings.filter((setting) => setting.id !== id)
    });
  },
  addSetting: async (setting) => {
    const response = await api.post('/api/settings/wallets', setting, { headers: authHeader() });

    set({ settings: [...new Set([...get().settings, response.data])] });
  },

  enableMultiOperador: async (id, multiUser) => {
    const response = await api.post(
      '/api/settings/multi',
      { id, multiUser },
      { headers: authHeader() }
    );
    set({ op: response.data });
  },

  saveTimerToBlockBot: async (timerOnScreenToBlock) => {
    const response = await api.post(
      '/api/settings/timerblockbot',
      { timerOnScreenToBlock },
      { headers: authHeader() }
    );
    set({ op: response.data });
  },

  restartNginx: async () => {
    const response = await api.post('/api/settings/restart-nginx', {}, { headers: authHeader() });
  },
  restartApi: async () => {
    const response = await api.post('/api/settings/restart-api', {}, { headers: authHeader() });
  },
  unlockIP: async (ip) => {
    const response = await api.post(
      '/api/settings/ip-unlock',
      {
        ip
      },
      { headers: authHeader() }
    );
  },

  unlockAllIps: async (ip) => {
    const response = await api.post(
      '/api/settings/unlock-all/ip-unlock',
      {
        ip
      },
      { headers: authHeader() }
    );
  }
});

store = devtools(store);
store = persist(store, { name: 'useUserSettings' });

const useStore = create(store);

export default useStore;
