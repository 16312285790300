import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { AiOutlineCloudDownload } from 'react-icons/ai';
import { BsEraser } from 'react-icons/bs';

import { useEffect } from 'react';
import useServerStore from '../../features/stores/server';

export default function ServerLogsDialog(props) {
  const { fetchLogs, logs, generateTxt, deleteLogs } = useServerStore();

  const [id, setID] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  const handleDownloadLogs = () => {
    generateTxt(props.name, 'other');
  };
  const handleClearLogs = () => {
    deleteLogs(props.id);
  };

  return (
    <Dialog
      open={props.isopenServerLogs}
      onClose={props.handleCloseServerLogs}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Logs do servidor ➡️ [{props.name}]</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box
            sx={{
              height: 500,
              width: 600
            }}
          >
            {logs.map((log, index) => (
              <Typography
                sx={{
                  paddingRight: 3,
                  fontSize: 12
                }}
                key={index}
              >
                {log.text.trim()}
              </Typography>
            ))}
          </Box>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="error" onClick={props.handleCloseServerLogs}>
          Cancelar
        </Button>
        <Button variant="outlined" color="error" onClick={handleClearLogs}>
          {' '}
          <BsEraser size={20} /> &nbsp; Limpar
        </Button>
        <Button variant="outlined" autoFocus onClick={handleDownloadLogs}>
          {' '}
          <AiOutlineCloudDownload size={22} /> &nbsp; Baixar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
