import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function CalcAcessoModal({ id, isOpen, handleClose }) {
  const [volume, setVolume] = React.useState(null);
  const [acesso, setAcesso] = React.useState(null);
  const [total, setTotal] = React.useState(0);

  const handleCalc = async () => {
    const calc1 = acesso / volume;
    const total = calc1 * 100;
    setTotal(0);
    setTotal(total.toFixed(2));
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Calculo de acessos</DialogTitle>
        <DialogContent>
          <Stack sx={{ width: 400 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <TextField
                sx={{
                  width: '100%',
                  mt: 1
                }}
                id="outlined-basic"
                label="Volume de acessos"
                variant="outlined"
                onChange={(e) => setVolume(e.target.value)}
                value={volume}
                type="number"
              />
              <TextField
                sx={{
                  width: '100%',
                  mt: 1
                }}
                id="outlined-basic"
                label="Quantidade de acessos"
                variant="outlined"
                onChange={(e) => setAcesso(e.target.value)}
                value={acesso}
                type="number"
              />

              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    fontSize: 18,
                    color: 'black',
                    fontWeight: 'bold'
                  }}
                >
                  Resultado:
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    fontSize: 18,
                    color: `${total >= 25 ? '#43CD5D' : 'black'}`,
                    fontWeight: 'bold'
                  }}
                >
                  {total} %
                </Typography>
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              handleClose();
              setVolume(0);
              setAcesso(0);
              setTotal(0);
            }}
          >
            Cancelar
          </Button>
          <Button autoFocus disabled={volume <= 0 || acesso <= 0} onClick={() => handleCalc()}>
            Calcular
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
