import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import useSettingsStore from '../../features/stores/settings';

export default function AddWalletDialog({ isOpenAddWallet, handleCloseWallet }) {
  const [nome, setNome] = React.useState();
  const [endereco, setEndereco] = React.useState();

  const { addSetting } = useSettingsStore();

  const handleAddNewWallet = async () => {
    if (nome && endereco) {
      const settings = {
        wallet_name: nome,
        wallet: endereco
      };

      await addSetting(settings);
      setNome('');
      setEndereco('');
      handleCloseWallet();
    } else {
      alert('Preencha todos os campos');
    }
  };

  return (
    <Dialog
      open={isOpenAddWallet}
      onClose={handleCloseWallet}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Cadastrar carteira</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: 150,
                  mt: 1
                }}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                onChange={(e) => setNome(e.target.value)}
                value={nome}
              />
              &nbsp;
              <TextField
                sx={{
                  width: 400,
                  mt: 1
                }}
                id="outlined-basic"
                label="Endereço"
                variant="outlined"
                onChange={(e) => setEndereco(e.target.value)}
                value={endereco}
              />{' '}
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleCloseWallet}>
          Cancelar
        </Button>
        <Button onClick={handleAddNewWallet} autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
