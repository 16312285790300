import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { BsFillHddNetworkFill } from 'react-icons/bs';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button
} from '@mui/material';
import useAppSettings from '../../../../features/stores/settings';
import useUsers from '../../../../features/stores/user';
import useServers from '../../../../features/stores/server';
import AddServerDialogBOT from './AddServerDialogBOT';
import ServerProxyBotDialog from '../../../../layouts/dashboard/ServerProxyBotDialog';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ServerListToolbarBOT.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function ServerListToolbarBOT({ numSelected, filterName, onFilterName }) {
  const [openAddServer, setOpenAddServer] = useState(false);
  const [openProxyBot, setOpenProxyBot] = useState(false);

  const { enableMultiOperador, op, fetchConfig, fetchSettingsWalletUser } = useAppSettings();
  const { userData } = useUsers();
  const { fetchMyProxyes } = useServers();

  const handleEnableMultiOperador = async (id, enabled) => {
    enableMultiOperador(id, enabled);
  };

  const handleCloseServer = () => {
    setOpenAddServer(false);
  };

  const handleClickOpenAddServer = () => {
    setOpenAddServer(true);
  };

  const handleClickCloseProxyPesquisa = () => {
    setOpenProxyBot(false);
  };

  const handleClickOpenProxyPesquisa = async () => {
    await fetchMyProxyes();
    setOpenProxyBot(true);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      <AddServerDialogBOT isOpen={openAddServer} handleClose={handleCloseServer} />

      <ServerProxyBotDialog
        name="PESQUISA"
        isopenProxyBot={openProxyBot}
        handleClickCloseProxyPesquisa={handleClickCloseProxyPesquisa}
      />

      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected}
          {numSelected > 1 ? ' selecionados' : ' selecionado'}
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Pesquisar servidores..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 330
            }}
          >
            <Button
              sx={{
                width: 170,
                height: 40
              }}
              variant="outlined"
              onClick={handleClickOpenProxyPesquisa}
            >
              <BsFillHddNetworkFill />
              &nbsp; Proxys Pesquisa
            </Button>
            <Button
              sx={{
                width: 150,
                height: 40
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={handleClickOpenAddServer}
            >
              Novo servidor
            </Button>
          </Box>
        </Box>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Deletar">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : null}
    </RootStyle>
  );
}
