export default function authHeaderFile() {
  const user = JSON.parse(sessionStorage.getItem('userData'));
  if (user && user.access_token) {
    return {
      Authorization: `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data'
    };
  }
  return {};
}
