import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import useSwapStore from '../../../../features/stores/swap';

export default function AddDominiolDialog({ isOpen, handleClose }) {
  const [name, setName] = React.useState();
  const [rede, setRede] = React.useState();
  const [addr, setAddr] = React.useState();

  const { addWallet } = useSwapStore();

  const handleAddNewWallet = async () => {
    if (name && rede && addr) {
      await addWallet(name, rede, addr);
      setName('');
      setRede('');
      setAddr('');
      handleClose();
    } else {
      alert('Preencha todos os campos');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Cadastrar Endereço 💰</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                  sx={{
                    width: '35%',
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />{' '}
                <TextField
                  sx={{
                    width: '60%',
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="Rede"
                  variant="outlined"
                  onChange={(e) => setRede(e.target.value)}
                  value={rede}
                />{' '}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextField
                  sx={{
                    width: 500,
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="Endereço"
                  variant="outlined"
                  onChange={(e) => setAddr(e.target.value)}
                  value={addr}
                />{' '}
              </Box>
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={handleAddNewWallet} autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
