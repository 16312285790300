import { Grid, Container, Typography, Box, Button } from '@mui/material';
import { BiBlock } from 'react-icons/bi';
// components
import Page from '../components/Page';
// sections
import { AppAdsClicks } from '../components/_dashboard/app';
import useAdsClicks from '../features/stores/ads-clicks';
// ----------------------------------------------------------------------

export default function AdsClicks() {
  const { generateIpsBlockTxt, deleteAll, adsClicks } = useAdsClicks();

  return (
    <Page title="Ads - Clicks">
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            Ads Clicks
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: 310,
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              textAlign: 'right'
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: '#DD4913',
                color: '#DD4913',
                '&:hover': { borderColor: '#DD4913', bgcolor: '#DD4913', color: 'white' }
              }}
              onClick={() => generateIpsBlockTxt('ip')}
            >
              Baixar IPs &nbsp;
              <BiBlock />
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: '#27C368',
                color: '#27C368',
                '&:hover': { borderColor: '#27C368', bgcolor: '#27C368', color: 'white' }
              }}
              onClick={deleteAll}
            >
              Excluir todos 🗑&nbsp;
            </Button>
          </Box>
        </Box>

        <Grid item xs={12} md={12} lg={12}>
          <AppAdsClicks
            title="Todos os Acessos"
            list={adsClicks
              .sort((a, b) => (a.id > b.id ? -1 : 1))
              .map((result) => ({
                id: result.id,
                ip: result.ip_addr,
                lang: result.lang,
                referer: result.referer,
                city: result.city,
                state: result.state,
                dns: result.dns,
                clicks: result.clicks,
                userAgent: result.user_agent,
                updatedAt: result.updatedAt,
                status: result.status,
                country: result.country
              }))}
          />
        </Grid>
      </Container>
    </Page>
  );
}
