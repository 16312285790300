import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Box,
  Card,
  Checkbox,
  CardHeader,
  Typography,
  FormControlLabel,
  Stack
} from '@mui/material';
import useUserStore from '../../../features/stores/user';

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  task: PropTypes.string,
  checked: PropTypes.bool,
  formik: PropTypes.object
};

function TaskItem({ id, nick, formik, checked }) {
  const { getFieldProps } = formik;
  const { setUserAsOperador } = useUserStore();

  const handleCheck = async (id) => {
    await setUserAsOperador(id, !checked);
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ py: 0.75 }}>
      <FormControlLabel
        control={
          <Checkbox
            {...getFieldProps('checked')}
            value={nick}
            checked={checked}
            onChange={(e) => handleCheck(id)}
          />
        }
        label={
          <Typography
            variant="body2"
            sx={{
              color: 'text.disabled',
              ...(checked && {
                color: 'black'
              })
            }}
          >
            {nick}
          </Typography>
        }
      />
    </Stack>
  );
}

export default function AppTasks() {
  const { users, fetchUsers } = useUserStore();
  useEffect(() => {
    fetchUsers();
  }, []);

  const formik = useFormik({
    initialValues: {
      checked: [users[2]]
    },
    onSubmit: (values) => {}
  });

  const { handleSubmit } = formik;

  return (
    <Card>
      <CardHeader title="Operadores" />
      <Box sx={{ px: 3, py: 1 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {users.map((nick, index) => (
              <TaskItem
                key={index}
                id={nick.id}
                nick={nick.nick}
                formik={formik}
                checked={nick.isOperador}
              />
            ))}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
