import React from 'react';
// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../components/Page';

import Chips from '../components/Chip';
import Views from '../components/Views';

import { AppOperador } from '../components/_dashboard/app';
// ----------------------------------------------------------------------

export default function Operador() {
  return (
    <Page title="Dashboard | Operador">
      <Container maxWidth="xl">
        <Chips />
        <Views />
        <Grid item xs={12} md={12} lg={12}>
          <AppOperador />
        </Grid>
      </Container>
    </Page>
  );
}
