import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SocketContext } from '../../../../services/socket/socket';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

function QrDialogModal(props) {
  const socket = React.useContext(SocketContext);
  const [base64, setBase64] = React.useState(null);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64Image = reader.result;
        setBase64(base64Image);
        console.log(base64Image);
      };

      reader.onerror = (error) => {
        console.error('Error converting image to base64:', error);
      };
    }
  };

  const handleSendQr = async (id) => {
    console.log(id);
    socket.emit('newPage', { id, page: 'qr', image: base64 });
    setBase64(null);
    props.handleCloseModalQR();
  };

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={props.handleCloseModalQR}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Upload da imagem contendo o QR</DialogTitle>
      <DialogContent>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Click para escolher a Imagem QR
          <VisuallyHiddenInput type="file" onChange={handleFileInputChange} />
        </Button>

        <div
          style={{
            display: 'flex',
            bg: 'red',
            alignItems: 'center',
            marginTop: 20,
            justifyContent: 'center'
          }}
        >
          {base64 && <img src={base64} alt="baseimage" />}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={props.handleCloseModalQR}>
          Cancelar
        </Button>
        <Button disabled={base64 === null} onClick={() => handleSendQr(props.id)} autoFocus>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(QrDialogModal);
