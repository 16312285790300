import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GiSheikahEye } from 'react-icons/gi';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// ----------------------------------------------------------------------
import AppDeleteServerDialog from '../app/AppDeleteServerDialog';
import EditServerDialog from '../../../layouts/dashboard/EditServerDialog';
import ServerLogsDialog from '../../../layouts/dashboard/ServerLogsDialog';

import useServerStore from '../../../features/stores/server';

export default function UserMoreMenu({ id, name, ip, api }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const { fetchLogs } = useServerStore();

  const [openEditUser, setOpenEditUser] = useState(false);
  const [openServerLogs, setOpenServerLogs] = useState(false);
  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const handleCloseServerLogs = () => {
    setOpenServerLogs(false);
  };

  const handleOpenServerLogs = async () => {
    await fetchLogs(id);
    setOpenServerLogs(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <AppDeleteServerDialog id={id} name={name} isOpenDialog={open} handleClose={handleClose} />
      <EditServerDialog
        id={id}
        name={name}
        ip={ip}
        api={api}
        isOpenEditUser={openEditUser}
        handleCloseEditUser={handleCloseEditUser}
      />

      <ServerLogsDialog
        id={id}
        name={name}
        isopenServerLogs={openServerLogs}
        handleCloseServerLogs={handleCloseServerLogs}
      />

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
          <ListItemIcon>
            <RiDeleteBinLine width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpenEditUser}>
          <ListItemIcon>
            <BiEditAlt width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpenServerLogs}>
          <ListItemIcon>
            <GiSheikahEye width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Logs" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
