// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { browserName, browserVersion } from 'react-device-detect';

//
import App from './App';
import AppNoBrowser from './AppNoBrowser';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <HashRouter>
      {browserName === 'Chrome' ? <App /> : <AppNoBrowser browser={browserName} />}
    </HashRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
reportWebVitals();
