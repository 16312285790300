import { useState, useEffect, useContext, useCallback } from 'react';
// material
import { Box, Stack, Typography, Button } from '@mui/material';
import { MdRouter, MdLocationCity, MdTimer, MdSignalWifi4Bar } from 'react-icons/md';
import { FaWolfPackBattalion, FaMousePointer } from 'react-icons/fa';
import { IoLanguage } from 'react-icons/io5';
import * as parser from 'ua-parser-js';
import ptLocale from 'date-fns/locale/pt-BR';
import { formatDistance } from 'date-fns';
import { CreateAdsClickColor } from '../../utils/ui/createColors';
import { CreateOsIcon } from '../../utils/ui/createIcons';
import useAdsClicks from '../../features/stores/ads-clicks';

import { SocketContext } from '../../services/socket/socket';

// ----------------------------------------------------------------------

export default function ListClicks() {
  const socket = useContext(SocketContext);

  const { adsClicks, fetchClicks, setBlock, updateStatus } = useAdsClicks();
  const [isOpen, setOpen] = useState(false);

  const handleBlockClient = (id, status) => {
    setBlock(id, !status);
  };

  const handleUpdateStatus = useCallback(
    (data) => {
      updateStatus(data);
    },
    [updateStatus]
  );

  useEffect(() => {
    fetchClicks();
  }, []);

  useEffect(() => {
    socket.on('adsClick', (data) => {
      handleUpdateStatus(data);
    });
    return () => {
      socket.off('adsClick');
    };
  }, [handleUpdateStatus, socket]);

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 80,
        justifyContent: 'space-around',
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: 0.5
      }}
    >
      {adsClicks &&
        adsClicks
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .slice(-3)
          .sort((a, b) => (a.id > b.id ? -1 : 1))
          .map((ads) => (
            <Box
              key={ads.id}
              sx={{
                display: 'flex',
                height: '30%',
                justifyContent: 'space-between',
                width: '100%',
                borderRadius: 0.5,
                textAlign: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '92.5%',
                  backgroundColor: `${CreateAdsClickColor(ads.status)}`,
                  borderRadius: 0.5
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 9,
                    fontWeight: 'bold',
                    color: `${ads.status ? 'white' : '#04297A'}`,
                    height: '100%',
                    width: '18%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <MdTimer size={15} />
                  {formatDistance(Date.parse(ads.updatedAt), new Date(), {
                    locale: ptLocale
                  })}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 9,
                    fontWeight: 'bold',
                    color: `${ads.status ? 'white' : '#04297A'}`,
                    height: '100%',
                    width: '18%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  {CreateOsIcon(parser(ads.userAgent).os.name)}
                  &nbsp; {parser(ads.userAgent).os.name}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 9,
                    fontWeight: 'bold',
                    color: `${ads.status ? 'white' : '#04297A'}`,
                    height: '100%',
                    width: '18%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  {ads.status ? <FaWolfPackBattalion size={15} /> : <FaMousePointer size={15} />}
                  &nbsp; Clicks: {ads.clicks}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 9,
                    fontWeight: 'bold',
                    color: `${ads.status ? 'white' : '#04297A'}`,
                    height: '100%',
                    width: '18%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <MdSignalWifi4Bar size={15} />
                  &nbsp;{ads.ip_addr}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 9,
                    fontWeight: 'bold',
                    color: `${ads.status ? 'white' : '#04297A'}`,
                    height: '100%',
                    width: '18%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  [<IoLanguage size={15} />
                  &nbsp;{ads.lang}]
                </Typography>
              </Box>
              <Button
                variant="outlined"
                onClick={() => handleBlockClient(ads.id, ads.status)}
                sx={{
                  borderRadius: 0.5,
                  width: 60,
                  borderColor: `${ads.status ? '#43BF2F' : '#DD4913'}`,
                  color: `${ads.status ? '#43BF2F' : '#DD4913'}`,
                  '&:hover': {
                    borderColor: `${ads.status ? '#43BF2F' : '#DD4913'}`,
                    bgcolor: `${ads.status ? '#43BF2F' : '#DD4913'}`,
                    color: 'white'
                  }
                }}
              >
                {ads.status ? 'Unblock' : 'Block'}
              </Button>
            </Box>
          ))}
    </Stack>
  );
}
