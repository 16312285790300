import create from 'zustand';
import { devtools } from 'zustand/middleware';
import authHeader from '../../services/auth/authHeader';
import api from '../../services/api/api';

const store = (set, get) => ({
  adsClicks: [],
  fetchClicks: async () => {
    const response = await api.get('/api/ads-click', {
      headers: authHeader()
    });
    set({ adsClicks: response.data });
  },
  deleteClick: async (id) => {
    await api.delete(`/api/ads-click/${id}`, { headers: authHeader() });
    set({
      adsClicks: get().adsClicks.filter((click) => click.id !== id)
    });
  },
  deleteAll: async () => {
    await api.delete('/api/ads-click/remove-all', { headers: authHeader() });
    set({
      adsClicks: []
    });
  },
  setBlock: async (id, status) => {
    const response = await api.patch(`/api/ads-click/${id}`, { status }, { headers: authHeader() });
    set((state) => ({
      adsClicks: state.adsClicks.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },
  addAccess: (access) => {
    set({ adsClicks: [...new Set([...get().adsClicks, access])] });
  },
  updateClicksCount: (payload) => {
    set((state) => ({
      adsClicks: state.adsClicks.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            clicks: payload.clicks
          };
        }
        return item;
      })
    }));
  },
  updateStatus: (payload) => {
    set((state) => ({
      adsClicks: state.adsClicks.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            status: payload.status
          };
        }
        return item;
      })
    }));
  },
  generateIpsBlockTxt: (args) => {
    // const blocks = get().adsClicks.filter((r) => r.status === true);

    const element = document.createElement('a');
    let file = '';
    let filename = '';

    filename = args;
    get().adsClicks.forEach((item) => {
      file += `${item.ip_addr}\n`;
    });

    const blob = new Blob([file]);

    element.href = URL.createObjectURL(blob);
    element.download = `ips-${filename}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
});

const useStore = create(devtools(store));

export default useStore;
