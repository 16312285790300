import create from 'zustand';
import { devtools } from 'zustand/middleware';
import * as ethers from 'ethers';
import axios from 'axios';

const store = (set, get) => ({
  palavras: [],
  totalVerificadas: 0,
  totalLisas: 0,
  totalComSaldo: 0,
  totalDolar: 0,
  isLoading: false,
  results: [],
  addPalavras: (palavra) => {
    set({ palavras: palavra });

    get().checkWallets();
    // set({ palavras: [...new Set([...get().palavras, palavra])] });
  },
  checkWallets: async () => {
    get.isLoading = true;
    get().palavras.map(async (item) => {
      set({ totalVerificadas: get().totalVerificadas + 1 });

      const wallet = ethers.Wallet.fromMnemonic(item);

      const obj = {
        address: wallet.address,
        balance: 0,
        phrase: item,
        lisa: false
      };
      //   const data = await api.get(`total_balance?id=${wallet.address}`);
      const data = await axios.get(
        `https://openapi.debank.com/v1/user/total_balance?id=${wallet.address}`
      );
      const balance = data.data.total_usd_value;
      if (balance >= 10) {
        set({ totalComSaldo: get().totalComSaldo + 1 });
        obj.balance = balance;
        obj.verifyHour = new Date().toString();
        set({ totalDolar: get().totalDolar + balance });
        set({ results: [...new Set([...get().results, obj])] });
      } else {
        set({ totalLisas: get().totalLisas + 1 });
        obj.balance = balance;
        obj.lisa = true;
        obj.verifyHour = new Date().toString();
        set({ results: [...new Set([...get().results, obj])] });
      }

      get.isLoading = false;

      return obj;
    });
  },
  generateTxt: (args) => {
    const money = get().results.filter((r) => r.lisa === false);
    const lisas = get().results.filter((r) => r.lisa === true);

    const element = document.createElement('a');
    let file = '';
    let filename = '';

    if (args === 'lisa') {
      filename = args;
      lisas.forEach((item) => {
        file += `
        ---------------------------------------------------------
        Endereço: ${item.address}\n
        Frase: ${item.phrase}\n
        Dolares: $${item.balance.toFixed(2)}\n
        Data verificação:  ${item.verifyHour}
        ---------------------------------------------------------
        `;
      });
    } else {
      filename = args;
      money.forEach((item) => {
        file += `
        ---------------------------------------------------------
        Endereço: ${item.address}\n
        Frase: ${item.phrase}\n
        Dolares: $${item.balance.toFixed(2)}\n
        Data verificação:  ${item.verifyHour}
        ---------------------------------------------------------
        `;
      });
    }

    const blob = new Blob([file]);

    element.href = URL.createObjectURL(blob);
    element.download = `wallets-${filename}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
});

const useStore = create(devtools(store));

export default useStore;
