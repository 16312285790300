// ----------------------------------------------------------------------

export const CreateOsColor = (os) => {
  switch (os) {
    case 'Android':
      return '#00900E';
    case 'Windows':
      return '#04287A';
    case 'Mac OS':
      return '#04297A';
    case 'Linux':
      return '#78216F';
    case 'ios':
      return '#0C53B7';
    default:
      return '#FF0890';
  }
};

export const CreateTextOnlineColor = (status) => {
  switch (status) {
    case 'Online':
      return '#00900E';
    case 'Ausente':
      return '#E39C00';
    case 'Offline':
      return '#C90023';
    default:
      return '#FF0890';
  }
};

export const CreateBrowserColor = (browser) => {
  switch (browser) {
    case 'Chrome':
      return '#9150EE';
    case 'Firefox':
      return '#F24F37';
    case 'Opera':
      return '#FF0000';
    case 'Safari':
      return '#2295EE';
    default:
      return '#FF0890';
  }
};

export const CreateUserStatusColor = (status) => {
  switch (status) {
    case 'Online':
      return '#30C96D';
    case 'Ausente':
      return '#FB8C00';
    case 'Offline':
      return '#9A9FA6';

    default:
      return '#9A9FA6';
  }
};

export const CreateUserStatusTextColor = (status) => {
  switch (status) {
    case 'Online':
      return '#FFFFFF';
    case 'Ausente':
      return '#FFFFFF';
    case 'Offline':
      return '#FFFFFF';

    default:
      return '#04297A';
  }
};

export const CreateAdsClickColor = (status) => {
  if (status === true) {
    return '#B82A42';
  }
  return '#D1F2FF';
};

export const CreateIpBlockColor = (click) => {
  if (click >= 6) {
    return '#B82A42';
  }
  return '#D1F2FF';
};

export const CreateNotifyColor = (data) => {
  const texto = data.toString();
  const chave = texto.split(' ');
  if (chave[0] === 'Enviou') {
    return '#00900E';
  }
  if (chave[0] === 'Recaptcha') {
    return '#00900E';
  }
  if (chave[0] === 'Aguardando') {
    return '#E49C00';
  }
  if (chave[0] === 'Bloqueado') {
    return '#C90122';
  }
  if (chave[0] === 'Cliente') {
    return '#00900E';
  }
  return '#04297A';
};

export const CreateVisitedColor = (visited) => {
  switch (visited) {
    case 1:
      return 'error';
    case 2:
      return 'primary';
    default:
      return 'info';
  }
};
