import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';
import authHeaderFile from '../../services/auth/authHeaderFile';

const store = (set, get) => ({
  servers: [],
  serversBot: [],
  logs: [],
  myLogs: [],
  myProxyes: [],
  proxyesByServer: [],
  fetchservers: async () => {
    const response = await api.get('/api/server', {
      headers: authHeader()
    });
    set({ servers: response.data });
  },

  fetchMyLogsLogs: async () => {
    const response = await api.get('/api/server/logs/txt', {
      headers: authHeader()
    });
    const data = response.data.split('\n').map((x) => ({ text: x }));
    set({ myLogs: data });
  },

  deleteMyLogsLogs: async () => {
    await api.post(
      '/api/server/logs/txt',
      {},
      {
        headers: authHeader()
      }
    );
    set({ myLogs: [] });
  },

  fetchLogs: async (id) => {
    const response = await api.get(`/api/server/servidor/${id}`, {
      headers: authHeader()
    });

    set({ logs: response.data });
  },

  deleteLogs: async (id) => {
    await api.delete(`/api/server/servidor/${id}`, {
      headers: authHeader()
    });
    set({
      logs: []
    });
  },

  addServer: async (name, ip, napi) => {
    const response = await api.post(
      '/api/server',
      { name, ip, api: napi },
      {
        headers: authHeader()
      }
    );
    set({ servers: [...new Set([...get().servers, response.data])] });
  },
  editServer: async (id, name, ip, napi) => {
    const response = await api.patch(
      `/api/server/${id}`,
      { name, ip, api: napi },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      servers: state.servers.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            name: response.data.name,
            ip: response.data.ip,
            api: response.data.api
          };
        }
        return item;
      })
    }));
  },
  deleteServer: async (id) => {
    await api.delete(`/api/server/${id}`, { headers: authHeader() });
    set({
      servers: get().servers.filter((server) => server.id !== id)
    });
  },
  generateTxt: (args, data) => {
    const element = document.createElement('a');
    let file = '';
    let filename = '';

    filename = args;

    if (data === 'myserver') {
      get().myLogs.forEach((item) => {
        file += `LOG => ${item.text}\n`;
      });
    }
    if (data === 'other') {
      get().logs.forEach((item) => {
        file += `LOG => ${item.text}\n`;
      });
    }

    const blob = new Blob([file]);

    element.href = URL.createObjectURL(blob);
    element.download = `logs-${filename}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  },

  // BOTS
  createBotServers: async (ip, clicks, tipo) => {
    const response = await api.post(
      '/api/server/botservers/bt',
      {
        ip,
        clicks,
        tipo
      },
      {
        headers: authHeader()
      }
    );
    set({ serversBot: [...new Set([...get().serversBot, response.data])] });
  },

  fetchBotServers: async () => {
    const response = await api.get('/api/server/botservers/bt', {
      headers: authHeader()
    });
    set({ serversBot: response.data });
  },

  deleteBotServers: async (id) => {
    const response = await api.delete(`/api/server/botservers/del/${id}`, {
      headers: authHeader()
    });
    set({
      serversBot: get().serversBot.filter((server) => server.id !== response.data.id)
    });
  },

  // PROXYES
  fetchMyProxyes: async () => {
    const response = await api.get('/api/server/proxys/txt', {
      headers: authHeader()
    });
    const data = response.data.split('\n').map((x) => ({ text: x }));
    set({ myProxyes: data });
  },

  handleUploadProxyBot: async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await api.post('/api/server/proxys/txt', formData, {
        headers: authHeaderFile()
      });
      alert('Arquivo enviado com sucesso!');
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert('Erro ao enviar o arquivo!');
    }
  },

  // PROXYES BY SERVER
  fetchProxyesByServer: async (id) => {
    get().proxyesByServer = [];
    const response = await api.get(`/api/server/${id}/proxys/txt`, {
      headers: authHeader()
    });
    const data = response.data.split('\n').map((x) => ({ text: x }));
    set({ proxyesByServer: data });
  },

  handleUploadProxyByServer: async (id, file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await api.post(`/api/server/${id}/upload/proxys/txt`, formData, {
        headers: authHeaderFile()
      });
      alert('Arquivo enviado com sucesso!');
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert('Erro ao enviar o arquivo!');
    }
  }
});

const useStore = create(devtools(store));

export default useStore;
